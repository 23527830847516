const MinutesToHoursFormat = ({ minutes }) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedTime = `${hours.toString().padStart(2, "0")}:${remainingMinutes
    .toString()
    .padStart(2, "0")}`;

  return <>{formattedTime}</>;
};

export default MinutesToHoursFormat;
