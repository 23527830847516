import React, { useCallback, useEffect, useState } from "react";
import "./watchTour.css";
import { IoCloseSharp } from "react-icons/io5";

const WatchTour = ({ close, link, time, ticketsId, index, loadPage }) => {
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const [remainingTime, setRemainingTime] = useState(time * 60);
  const [formattedTime, setFormattedTime] = useState(formatTime(remainingTime));
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const tick = useCallback(() => {
    setRemainingTime((prevTime) => prevTime - 1);
    setFormattedTime(formatTime(remainingTime - 1));
  }, [remainingTime, formatTime]);

  const handleClose = () => {
    loadPage.current = true;
    close(false);
  };

  const handelUseTicket = async () => {
    const formUseTicket = new FormData();
    formUseTicket.append("validation", validation);
    formUseTicket.append("ticketsId", ticketsId);
    formUseTicket.append("index", index);

    try {
      const responseUseTicket = await fetch(
        `${LinkLikeBachend}update/watch_ticket.php`,
        {
          method: "POST",
          body: formUseTicket,
        }
      );
      const dataUseTicket = await responseUseTicket.text();

    //   console.log(dataUseTicket);
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      tick();
      if (remainingTime % 60 === 0) {
        handelUseTicket();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [tick, remainingTime]);

  useEffect(() => {
    setFormattedTime(formatTime(remainingTime));
  }, [remainingTime]);

  return (
    <div id="watch_tour">
      <div className="top">
        <IoCloseSharp className="closeSharp" onClick={handleClose} />

        <div className="time_watch">{formattedTime}</div>
      </div>
      <div className="bottom">
        <iframe src={link}></iframe>
      </div>
    </div>
  );
};

export default WatchTour;
