import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useAppContext } from "./components/context/AppProvider";
import NotFound from "./components/notFound/NotFound";
import HeaderAdmin from "./admin/dashboard/headerAdmin/HeaderAdmin";
import Authentication from "./admin/Authentication";
import Navbar from "./components/navbar/Navbar";
import Home from "./page/home/Home";
import About from "./page/about/About";
import PrivacyPolicy from "./page/privacyPolicy/PrivacyPolicy";
import Contact from "./page/contact/Contact";
import Register from "./page/login/register/Register";
import Login from "./page/login/Login";
import ForgotPassword from "./page/login/ForgotPassword";
import AccountActivated from "./page/login/AccountActivated";
import Account from "./user/account/Account";
import PurchaseHistory from "./user/purchaseHistory/PurchaseHistory";
import RequirAuth from "./components/auth/RequirAuth";
import ActivationCode from "./page/login/ActivationCode";
import ChangePassword from "./page/login/ChangePassword";
import AdminAbout from "./admin/dashboard/adminAbout/AdminAbout";
import AdminPrivacyPolicy from "./admin/dashboard/adminPrivacyPolicy/AdminPrivacyPolicy";
import AdminContact from "./admin/dashboard/adminContact/AdminContact";
import AdminUser from "./admin/dashboard/adminUser/AdminUser";
import UpdateUser from "./admin/dashboard/adminUser/updateUser/UpdateUser";
import SearchUser from "./admin/dashboard/adminUser/searchUser/SearchUser";
import VirtualTours from "./admin/dashboard/virtualTours/VirtualTours";
import AddTours from "./admin/dashboard/virtualTours/addTours/AddTours";
import UpdateTours from "./admin/dashboard/virtualTours/updateTours/UpdateTours";
import ViewTours from "./page/home/viewTours/ViewTours";
import ResPayment from "./components/payment/resPayment/ResPayment";
import Discount from "./admin/dashboard/virtualTours/discount/Discount";
import ViewPurchase from "./user/purchaseHistory/viewPurchase/ViewPurchase";
import AdminHome from "./admin/dashboard/adminHome/AdminHome";
import AdminPurchases from "./admin/dashboard/adminPurchases/AdminPurchases";
import AdminViewPurchase from "./admin/dashboard/adminPurchases/adminViewPurchase/AdminViewPurchase";
import TicketReferral from "./user/purchaseHistory/ticketReferral/TicketReferral";
import AdminChangePassword from "./admin/dashboard/adminChangePassword/AdminChangePassword";
import InfoAccount from "./user/account/InfoAccount";
import TayebatCityEvents from "./page/tayebatCityEvents/TayebatCityEvents";
import HowUse from "./page/howUse/HowUse";
import AdminTayebatCityEvents from "./admin/dashboard/adminTayebatCityEvents/AdminTayebatCityEvents";
import AdminHowUse from "./admin/dashboard/adminHowUse/AdminAbout";
const Lazyloadeig = React.lazy(() => import("./page/chooseLang/ChooseLang"));

const App = () => {
  const location = useLocation();
  const { direction, setDirection } = useAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location.pathname == "/payment") {
      setLoading(false);
    } else {
      setLoading(true);
    }

    window.scrollTo(0, 0);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [location.pathname]);

  return (
    <div
      className={`direction direction_${direction}`}
      style={{ direction: direction }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense>
              <Lazyloadeig
                setDirection={setDirection}
                direction={direction}
                loading={loading}
              />
            </React.Suspense>
          }
        />

        <Route path="/" element={<Navbar />}>
          <Route path="/home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="tayebat-city-events" element={<TayebatCityEvents />} />
          <Route path="how-to-use" element={<HowUse />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="account-activated" element={<AccountActivated />} />
          <Route path="activation-code" element={<ActivationCode />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="view-tours/:idTours" element={<ViewTours />} />
          <Route
            path="info-account"
            element={
              <RequirAuth>
                <InfoAccount />
              </RequirAuth>
            }
          />
          <Route
            path="account"
            element={
              <RequirAuth>
                <Account />
              </RequirAuth>
            }
          />
          <Route
            path="purchase-history"
            element={
              <RequirAuth>
                <PurchaseHistory />
              </RequirAuth>
            }
          />
          <Route
            path="ticket-referral"
            element={
              <RequirAuth>
                <TicketReferral />
              </RequirAuth>
            }
          />
          <Route
            path="payment"
            element={
              <RequirAuth>
                <ResPayment />
              </RequirAuth>
            }
          />
          <Route
            path="view-purchase/:ticketsId"
            element={
              <RequirAuth>
                <ViewPurchase />
              </RequirAuth>
            }
          />
        </Route>

        <Route path="/admin" element={<Authentication />} />

        <Route path="/dashboard" element={<HeaderAdmin />}>
          <Route path="/dashboard" element={<AdminHome />} />
          <Route path="admin-about" element={<AdminAbout />} />
          <Route path="admin-privacy-policy" element={<AdminPrivacyPolicy />} />
          <Route path="admin-how-to-use" element={<AdminHowUse />} />
          <Route
            path="admin-tayebat-city-events"
            element={<AdminTayebatCityEvents />}
          />
          <Route path="admin-contact" element={<AdminContact />} />
          <Route path="admin-user" element={<AdminUser />} />

          <Route path="update-user/:idUser" element={<UpdateUser />} />
          <Route path="search-user/:idSearch" element={<SearchUser />} />

          <Route path="virtual-tours" element={<VirtualTours />} />
          <Route path="add-tours" element={<AddTours />} />
          <Route path="update-tours/:idTours" element={<UpdateTours />} />
          <Route path="discount" element={<Discount />} />

          <Route path="admin-purchases" element={<AdminPurchases />} />

          <Route
            path="admin-change-password"
            element={<AdminChangePassword />}
          />
          <Route
            path="admin-view-purchase/:ticketsId"
            element={<AdminViewPurchase />}
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
