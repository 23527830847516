import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import "./viewPurchase.css";
import MinutesToHoursFormat from "./MinutesToHoursFormat";
import WatchTour from "../../../components/watchTour/WatchTour";

const ViewPurchase = () => {
  const { ticketsId } = useParams();
  const navigate = useNavigate();
  const siteOrigin = window.location.origin;

  const token = Cookies.get("token");
  const [allPurchaseHistory, setPurchaseHistory] = useState([]);
  const [infoDiscount, setInfoDiscount] = useState([]);
  const [idOffer, setIdOffer] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isWatchTour, setWatchTour] = useState(false);

  const [link, setLink] = useState("");
  const [time, setTime] = useState("");
  const [index, setIndex] = useState("");

  const [isHide, setIsHide] = useState(false);

  const [referralEmail, setReferralEmail] = useState("");

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const { t } = useTranslation();

  const handlePurchaseHistory = async () => {
    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("token", token);
    FormDataSubmit.append("ticketsId", ticketsId);

    const response = await fetch(
      `${LinkLikeBachend}read/purchase_history.php`,
      {
        method: "POST",
        body: FormDataSubmit,
      }
    );

    if (response.ok) {
      try {
        const resultData = await response.json();
        setPurchaseHistory(resultData);
        setIdOffer(resultData[0].id_offer);
      } catch (error) {
        console.error("Error :", error);
      }
    }
  };

  const handleDiscount = async () => {
    const formDiscount = new FormData();
    formDiscount.append("validation", validation);
    formDiscount.append("discountID", idOffer);

    try {
      const responseUser = await fetch(
        `${LinkLikeBachend}read/discount_view_admin.php`,
        {
          method: "POST",
          body: formDiscount,
        }
      );
      const discount = await responseUser.json();
      setInfoDiscount(discount);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDataAccount = async () => {
    const formDataAccount = new FormData();
    formDataAccount.append("validation", validation);
    formDataAccount.append("token", token);

    try {
      const responseSliderProducts = await fetch(
        `${LinkLikeBachend}read/view_user_account.php`,
        {
          method: "POST",
          body: formDataAccount,
        }
      );
      const dataSliderProducts = await responseSliderProducts.json();
      setUserEmail(dataSliderProducts[0].email);
    } catch (error) {
      Cookies.remove("successfully");
      Cookies.remove("token");

      navigate("/login", { replace: true });

      // console.error("Error:", error);
    }
  };

  let loadPage = useRef(true);

  useEffect(() => {
    if (loadPage.current) {
      loadPage.current = false;
      handlePurchaseHistory();
      handleDiscount();
      fetchDataAccount();
    }
  }, [idOffer, loadPage.current]);

  const handelLinkWatchTour = (link, time, index) => {
    setLink(link);
    setTime(time);
    setIndex(index);

    setWatchTour(true);
  };

  const handelUseTicket = async (index) => {
    setIsHide(true);

    const formUseTicket = new FormData();
    formUseTicket.append("validation", validation);
    formUseTicket.append("ticketsId", ticketsId);
    formUseTicket.append("index", index);
    formUseTicket.append("userEmail", userEmail);

    try {
      const responseUseTicket = await fetch(
        `${LinkLikeBachend}update/use_ticket.php`,
        {
          method: "POST",
          body: formUseTicket,
        }
      );
      const dataUseTicket = await responseUseTicket.text();

      // console.log(dataUseTicket);
      setIsHide(false);
      handlePurchaseHistory();
      handleDiscount();
    } catch (error) {
      console.error("Error fetching:", error);
      setIsHide(false);
    }
  };

  const handleFormSubmit = async (index, e) => {
    e.preventDefault();

    setIsHide(true);

    const formReferralEmail = new FormData();
    formReferralEmail.append("validation", validation);
    formReferralEmail.append("ticketsId", ticketsId);
    formReferralEmail.append("index", index);
    formReferralEmail.append("userEmail", referralEmail);
    formReferralEmail.append("sender_email", userEmail);
    formReferralEmail.append("siteOrigin", siteOrigin);

    try {
      const responseReferralEmail = await fetch(
        `${LinkLikeBachend}update/use_ticket_new_user.php`,
        {
          method: "POST",
          body: formReferralEmail,
        }
      );
      const dataReferralEmail = await responseReferralEmail.text();

      // console.log(dataReferralEmail);

      setIsHide(false);

      handlePurchaseHistory();
      handleDiscount();
    } catch (error) {
      console.error("Error fetching:", error);
      setIsHide(false);
    }
  };

  // console.log(allPurchaseHistory);
  // console.log(infoDiscount);
  // console.log(userEmail);
  // console.log(siteOrigin);
  // console.log(loadPage.current);

  return (
    <>
      {isWatchTour && (
        <WatchTour
          close={setWatchTour}
          link={link}
          time={time}
          ticketsId={ticketsId}
          index={index}
          loadPage={loadPage}
        />
      )}

      {allPurchaseHistory &&
        allPurchaseHistory.length > 0 &&
        allPurchaseHistory?.map((item, i) => {
          const payment_information = JSON.parse(item.payment_information);
          let dataArray = "";

          if (
            item.referral_tickets_tour != "" &&
            item.referral_tickets_tour != null
          ) {
            const referral_tickets_tour = JSON.parse(
              item.referral_tickets_tour
            );
            dataArray = Object.keys(referral_tickets_tour).map(
              (key) => referral_tickets_tour[key]
            );
          }

          return (
            <div className="view_purchase" key={i}>
              <div className="img_picture">
                <img src={`${LinkLikeBachend}${item.picture}`} alt="picture" />
              </div>
              {item.payment_status == 1 ? (
                <b>
                  {t("payment_status")}:{" "}
                  <small style={{ color: "green" }}>Successful payment</small>
                </b>
              ) : (
                <b>
                  {t("payment_status")}:{" "}
                  <small style={{ color: "red" }}>Failed payment</small>
                </b>
              )}
              <b>
                {t("date_payment")}: <small>{item.date_payment}</small>
              </b>
              <b>
                {t("number_tickets")}: <small>{item.number_tickets}</small>
              </b>
              <div className="payment_information">
                <b>
                  {t("payment_id")}: <small>{item.id_payment}</small>
                </b>

                <b>
                  {t("amount_format")}:{" "}
                  <small>
                    {payment_information.amount_cents / 100} {t("saudi_riyal")}
                  </small>
                </b>

                {infoDiscount && infoDiscount.length > 0 ? (
                  <b>
                    {t("has_been_discount")}:{" "}
                    <small>{infoDiscount[0].discount}%</small>
                  </b>
                ) : (
                  <b>
                    {t("has_been_discount")}: <small>No offer</small>
                  </b>
                )}
              </div>

              <div className="payment_information">
                {dataArray &&
                  dataArray.length > 0 &&
                  dataArray.map((ref, i) =>
                    ref.email == "" ? (
                      <form
                        className="div_payment_information"
                        key={i}
                        onSubmit={(e) => handleFormSubmit(i, e)}
                      >
                        <b>
                          {t("invitation_mail")}:{" "}
                          <input
                            required
                            type="email"
                            name="invitation_mail"
                            id="invitation_mail"
                            placeholder={t("email")}
                            onChange={(e) => setReferralEmail(e.target.value)}
                          />
                        </b>

                        {ref.isWatched == 0 ? (
                          <>
                            <b>
                              {t("viewing_status")}:{" "}
                              <small style={{ color: "orange" }}>waiting</small>
                            </b>
                          </>
                        ) : (
                          <>
                            <b>
                              {t("viewing_status")}:{" "}
                              <small style={{ color: "green" }}>Watched</small>
                            </b>
                          </>
                        )}

                        <b>
                          {t("watch_time")}:{" "}
                          <small>
                            <MinutesToHoursFormat minutes={ref.time} />
                          </small>
                        </b>

                        <div
                          className="submit_use_ticket"
                          style={{ display: isHide ? "none" : "flex" }}
                        >
                          <div
                            onClick={() => handelUseTicket(i)}
                            className="use_ticket"
                          >
                            {t("use_ticket")}
                          </div>

                          <input type="submit" value={t("submit")} />
                        </div>
                      </form>
                    ) : (
                      <div className="div_payment_information" key={i}>
                        {userEmail == ref.email ? (
                          <></>
                        ) : (
                          <b>
                            {t("invitation_mail")}: <small>{ref.email}</small>
                          </b>
                        )}

                        {ref.isWatched == 0 ? (
                          <>
                            <b>
                              {t("viewing_status")}:{" "}
                              <small style={{ color: "orange" }}>
                                {t("waiting")}
                              </small>
                            </b>

                            {userEmail == ref.email ? (
                              <b>
                                {t("link_watch_tour")}:{" "}
                                <small>
                                  <button
                                    onClick={() =>
                                      handelLinkWatchTour(
                                        item.link_watch_tour,
                                        ref.time,
                                        i
                                      )
                                    }
                                  >
                                    {t("watch")}
                                  </button>
                                </small>
                              </b>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            {ref.time == 0 ? (
                              <b>
                                {t("viewing_status")}:{" "}
                                <small style={{ color: "red" }}>
                                  {t("watched")}
                                </small>
                              </b>
                            ) : (
                              <>
                                <b>
                                  {t("viewing_status")}:{" "}
                                  <small style={{ color: "green" }}>
                                    {t("watches")}
                                  </small>
                                </b>

                                {userEmail == ref.email ? (
                                  <b>
                                    {t("link_watch_tour")}:{" "}
                                    <small>
                                      <button
                                        onClick={() =>
                                          handelLinkWatchTour(
                                            item.link_watch_tour,
                                            ref.time,
                                            i
                                          )
                                        }
                                      >
                                        {t("watch")}
                                      </button>
                                    </small>
                                  </b>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}

                        <b>
                          {t("watch_time")}:{" "}
                          <small>
                            <MinutesToHoursFormat minutes={ref.time} />
                          </small>
                        </b>
                      </div>
                    )
                  )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default ViewPurchase;
