import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./viewTours.css";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { MdOutlinePayment } from "react-icons/md";
import PaymentForm from "../../../components/payment/PaymentForm";

const ViewTours = () => {
  const { idTours } = useParams();
  const [viewTours, setViewTours] = useState([]);
  const { t, i18n } = useTranslation();
  const tokenCookieValue = Cookies.get("token");
  const [shopping, setshopping] = useState(false);
  const navigate = useNavigate();

  const [paymentForm, setPaymentForm] = useState(false);
  const [account, setAccount] = useState(null);

  const fetchDataAccount = async () => {
    const formDataAccount = new FormData();
    formDataAccount.append("validation", validation);
    formDataAccount.append("token", tokenCookieValue);

    try {
      const responseAccount = await fetch(
        `${LinkLikeBachend}read/view_user_account.php`,
        {
          method: "POST",
          body: formDataAccount,
        }
      );
      const dataAccount = await responseAccount.json();

      setAccount(dataAccount);
    } catch (error) {
      Cookies.remove("successfully");
      Cookies.remove("token");

      navigate("/login", { replace: true });

      // console.error("Error:", error);
    }
  };

  let currentLanguage = i18n.language;
  let localization;

  if (currentLanguage === "ar") {
    localization = "ar";
  } else if (currentLanguage === "en") {
    localization = "en";
  } else if (currentLanguage === "fr") {
    localization = "fr";
  }

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataTours = async () => {
    const formDataTours = new FormData();
    formDataTours.append("validation", validation);
    formDataTours.append("idTours", idTours);

    try {
      const responseTours = await fetch(`${LinkLikeBachend}read/tours.php`, {
        method: "POST",
        body: formDataTours,
      });
      const dataTours = await responseTours.json();
      setViewTours(dataTours);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataTours();
    fetchDataAccount();

    if (tokenCookieValue) {
      setshopping(true);
    } else {
      setshopping(false);
    }
  }, []);

  const handleNoLogin = () => {
    sessionStorage.setItem("redirectFrom", window.location.pathname);

    navigate("/login");
  };

  const handlePayment = async () => {
    sessionStorage.setItem("redirectFrom", window.location.pathname);

    setPaymentForm(true);
  };

  // console.log(viewTours);

  return (
    <>
      {paymentForm && (
        <PaymentForm
          viewTours={viewTours}
          close={setPaymentForm}
          account={account}
        />
      )}

      <div className="user_view_tours">
        {viewTours.length > 0 && (
          <>
            {viewTours.map((item, i) => {
              let displayTitle, displayDescription;

              switch (localization) {
                case "ar":
                  displayTitle = item.title_ar;
                  displayDescription = item.description_ar;
                  break;
                case "en":
                  displayTitle = item.title_en;
                  displayDescription = item.description_en;

                  break;
                case "fr":
                  displayTitle = item.title_fr;
                  displayDescription = item.description_fr;

                  break;
                default:
                  displayTitle = item.title_ar;
                  displayDescription = item.description_ar;

                  break;
              }

              return (
                <React.StrictMode key={i}>
                  <div className="image_title">
                    <img
                      src={`${LinkLikeBachend}${item.picture}`}
                      alt={displayTitle}
                    />
                    <h2>{displayTitle}</h2>
                  </div>

                  <div
                    className="user_description"
                    dangerouslySetInnerHTML={{
                      __html: displayDescription,
                    }}
                  />

                  <div className="purchase_button">
                    {shopping ? (
                      <button className="btn" onClick={handlePayment}>
                        {t("buy_now")}
                        <MdOutlinePayment className="svg_icon" />
                      </button>
                    ) : (
                      <button className="btn" onClick={handleNoLogin}>
                        {t("buy_now")}
                        <MdOutlinePayment className="svg_icon" />
                      </button>
                    )}
                  </div>
                </React.StrictMode>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default ViewTours;
