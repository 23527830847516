import React from "react";
import { LiaCopyrightSolid } from "react-icons/lia";
import "./footer.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Copyrights = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <p>
        <LiaCopyrightSolid /> {t("copy_right")}{" "}
        <NavLink to="/">{t("name_site")}</NavLink>
      </p>
    </div>
  );
};

export default Copyrights;
