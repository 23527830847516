import React, { useState } from "react";
import background_img from "../../../../images/background_img.webp";
import "./addTours.css";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";

const AddTours = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const [editorDataAR, setEditorDataAR] = useState(null);
  const [editorDataEN, setEditorDataEN] = useState(null);
  const [editorDataFR, setEditorDataFR] = useState(null);

  const [editorConfigAR] = useState({
    placeholder: "وصف عربي",
    direction: "rtl",
    minHeight: "500",
  });

  const [editorConfigEN] = useState({
    placeholder: "وصف انجليزي",
    direction: "ltr",
    minHeight: "500",
  });

  const [editorConfigFR] = useState({
    placeholder: "وصف فرنساوي",
    direction: "ltr",
    minHeight: "500",
  });

  const handleSendcontentAR = (newContentAR) => {
    setEditorDataAR(newContentAR);
  };

  const handleSendcontentEN = (newContentEN) => {
    setEditorDataEN(newContentEN);
  };

  const handleSendcontentFR = (newContentFR) => {
    setEditorDataFR(newContentFR);
  };

  const [addAddTours, setAddAddTours] = useState({
    link_watch_tour: "",
    title_ar: "",
    title_en: "",
    title_fr: "",
    ticket_price: 0,
  });

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const handleChangeUpdate = (e) => {
    const { name, value } = e.target;
    setAddAddTours((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setSelectedImage(file);
  };

  const imageUrl = selectedImage
    ? URL.createObjectURL(selectedImage)
    : background_img;

  const handleAddTours_admin = async (e) => {
    e.preventDefault();
    setDisabledSubmit(true);

    if (
      editorDataAR != "" &&
      editorDataAR != null &&
      editorDataEN != "" &&
      editorDataEN != null &&
      editorDataFR != "" &&
      editorDataFR != null
    ) {
      const FormDataSubmit = new FormData();
      FormDataSubmit.append("validation", validation);
      FormDataSubmit.append("picture", selectedImage);
      FormDataSubmit.append("description_ar", editorDataAR);
      FormDataSubmit.append("description_en", editorDataEN);
      FormDataSubmit.append("description_fr", editorDataFR);

      Object.entries(addAddTours).forEach(([key, value]) => {
        FormDataSubmit.append(key, value);
      });

      const response = await fetch(`${LinkLikeBachend}create/tours.php`, {
        method: "POST",
        body: FormDataSubmit,
      });

      const resultText = await response.text();

      if (response.ok) {
        if (resultText == "successfully") {
          let picture = document.querySelector("#picture");
          picture.value = "";

          toast.success("تم اضافة البيانات بنجاح");

          setSelectedImage(null);
          setDisabledSubmit(false);

          setAddAddTours({
            link_watch_tour: "",
            title_ar: "",
            title_en: "",
            title_fr: "",
            ticket_price: 0,
          });

          let jodit_wysiwyg = document.querySelectorAll(".jodit-wysiwyg");

          jodit_wysiwyg.forEach(function (element) {
            element.innerHTML = "";
          });
        } else {
          toast.error("حدث خطأ عند اضافة البيانات");

          console.log(resultText);
          setDisabledSubmit(false);
        }
      } else {
        const errorText = await response.text();
        toast.error("حدث خطأ عند اضافة البيانات");
        console.log("حدث خطأ:", errorText);
        console.log(errorText);
        setDisabledSubmit(false);
      }
    } else {
      toast.error("الوصف فارغ");
    }
  };

  //   console.log(addAddTours);
  //   console.log(selectedImage);
  // console.log(editorDataAR);
  // console.log(editorDataEN);
  // console.log(editorDataFR);

  return (
    <div className="add_tours_admin">
      <h2>اضافة جولة</h2>
      <form
        className="add_tours_form"
        onSubmit={handleAddTours_admin}
        encType="multipart/form-data"
      >
        <div className="div_picture">
          <input
            type="file"
            name="picture"
            id="picture"
            required
            accept="image/*"
            onChange={handleFileChange}
          />
          {imageUrl && (
            <div>
              <img src={imageUrl} alt="background_img" />
            </div>
          )}
        </div>

        <div className="data_form">
          <input
            type="url"
            name="link_watch_tour"
            id="link_watch_tour"
            placeholder="رابط المشاهدة"
            required
            value={addAddTours.link_watch_tour || ""}
            onChange={handleChangeUpdate}
          />

          <br />

          <input
            type="text"
            name="title_ar"
            id="title_ar"
            placeholder="العنوان عربي"
            required
            value={addAddTours.title_ar || ""}
            onChange={handleChangeUpdate}
          />

          <input
            type="text"
            name="title_en"
            id="title_en"
            placeholder="العنوان انجليزي"
            required
            value={addAddTours.title_en || ""}
            onChange={handleChangeUpdate}
          />

          <input
            type="text"
            name="title_fr"
            id="title_fr"
            placeholder="العنوان فرنساوي"
            required
            value={addAddTours.title_fr || ""}
            onChange={handleChangeUpdate}
          />
        </div>

        <div className="data_form">
          <div className="ticket_price">
            <input
              type="number"
              name="ticket_price"
              id="ticket_price"
              placeholder="سعر التذكرة"
              required
              value={addAddTours.ticket_price || ""}
              onChange={handleChangeUpdate}
            />

            <p>ريال سعودي</p>
          </div>
        </div>

        <div className="data_form">
          <div className="description">
            <label htmlFor="description">الوصف عربي</label>
            <JoditEditor
              name="description_ar"
              id="description_ar"
              value={addAddTours.description_ar || ""}
              onChange={handleSendcontentAR}
              config={editorConfigAR}
              required
            />
          </div>

          <div className="description description_en">
            <label htmlFor="description">الوصف انجليزي</label>
            <JoditEditor
              value={addAddTours.description_en || ""}
              onChange={handleSendcontentEN}
              id="description_en"
              name="description_en"
              config={editorConfigEN}
              required
            />
          </div>

          <div className="description description_fr">
            <label htmlFor="description">الوصف فرنساوي</label>
            <JoditEditor
              value={addAddTours.description_fr || ""}
              onChange={handleSendcontentFR}
              id="description_fr"
              name="description_fr"
              config={editorConfigFR}
              required
            />
          </div>
        </div>

        <input
          type="submit"
          value={isDisabledSubmit ? "انتظر" : "اضافة"}
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
        />
      </form>
    </div>
  );
};

export default AddTours;
