import React, { useEffect, useState } from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [viewTours, setViewTours] = useState([]);
  const [toursCount, setToursCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;
  let localization;

  if (currentLanguage === "ar") {
    localization = "ar";
  } else if (currentLanguage === "en") {
    localization = "en";
  } else if (currentLanguage === "fr") {
    localization = "fr";
  }

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataTours = async () => {
    const formDataTours = new FormData();
    formDataTours.append("validation", validation);

    try {
      const responseTours = await fetch(`${LinkLikeBachend}read/tours.php`, {
        method: "POST",
        body: formDataTours,
      });
      const dataTours = await responseTours.json();
      setViewTours(dataTours);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDataToursCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);

      const responseToursCount = await fetch(
        `${LinkLikeBachend}read/tours_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataToursCount = await responseToursCount.text();

      setToursCount(dataToursCount);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewToursPage = new FormData();
    formDataViewToursPage.append("validation", validation);
    formDataViewToursPage.append("page", currentPage + 1);

    try {
      const responseTours = await fetch(`${LinkLikeBachend}read/Tours.php`, {
        method: "POST",
        body: formDataViewToursPage,
      });
      const dataTours = await responseTours.json();

      setTimeout(() => {
        setViewTours((prevData) => prevData.concat(dataTours));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataTours();
    fetchDataToursCount();
  }, []);

  const handleView = (idTours) => {
    navigate(`/view-tours/${idTours}`);
  };

  // console.log(viewTours);
  // console.log(toursCount);

  return (
    <>
      <div className="div_load_more">
        <h2>{t("tayebat_international")}</h2>
      </div>

      <div className="view_tours">
        {viewTours?.length > 0 ? (
          viewTours.map((item, i) => {
            let displayTitle;

            switch (localization) {
              case "ar":
                displayTitle = item.title_ar;
                break;
              case "en":
                displayTitle = item.title_en;
                break;
              case "fr":
                displayTitle = item.title_fr;
                break;
              default:
                displayTitle = item.title_ar;
                break;
            }

            return (
              <div
                className="tours_item"
                key={i}
                onClick={() => handleView(item.id)}
              >
                <img
                  src={`${LinkLikeBachend}${item.picture}`}
                  alt={displayTitle}
                />
                <p className="tours_item_title">
                  <span className="span_price">{displayTitle}</span>
                </p>
              </div>
            );
          })
        ) : (
          <p>{t("no_data")}</p>
        )}
      </div>

      <div className="div_load_more">
        {viewTours &&
          viewTours.length > 0 &&
          parseInt(toursCount) !== viewTours.length && (
            <button
              className="button load_more"
              onClick={handleLoadMore}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              {!isDisabledLoadMore ? t("load_more") : t("loading")}
            </button>
          )}
      </div>
    </>
  );
};

export default Home;
