import React, { createContext, useState, useContext } from "react";

export const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
  const [direction, setDirection] = useState("rtl");
  const [auth, setAuth] = useState({
    email: "",
    password: "",
  });

  const handleSetAuth = (em_password) => {
    setAuth(em_password);
  };

  return (
    <AppContext.Provider
      value={{ direction, setDirection, auth, handleSetAuth }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
