import React, { useEffect, useState } from "react";
import "./contact.css";
import { FaUser } from "react-icons/fa";
import { MdEmail, MdMessage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SocialMediaLinks from "../../components/socialMediaLinks/SocialMediaLinks";

const Contact = () => {
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [social_media, setSocial_media] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const { t } = useTranslation();

  const [contact, setContact] = useState({
    name_contact: "",
    email_contact: "",
    title_contact: "",
    message_contact: "",
  });

  const fetchDataAbout = async () => {
    const formDataAbout = new FormData();
    formDataAbout.append("validation", validation);

    try {
      const responseDataAbout = await fetch(
        `${LinkLikeBachend}read/social_media.php`,
        {
          method: "POST",
          body: formDataAbout,
        }
      );
      const dataAbout = await responseDataAbout.json();
      setSocial_media(dataAbout);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataAbout();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContact = async (e) => {
    e.preventDefault();

    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);

    Object.entries(contact).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    const response = await fetch(`${LinkLikeBachend}create/contact_us.php`, {
      method: "POST",
      body: FormDataSubmit,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success(t("message_success"));

        setContact({
          name_contact: "",
          email_contact: "",
          title_contact: "",
          message_contact: "",
        });

        setDisabledSubmit(false);
      } else {
        toast.error(t("message_error"));

        console.log(resultText);
        setDisabledSubmit(false);
      }
    } else {
      const errorText = await response.text();
      toast.error(t("message_error"));
      console.log("حدث خطأ:", errorText);
      setDisabledSubmit(false);
    }
  };

  // console.log(social_media);

  return (
    <div id="contact">
      <div className="contact">
        <form className="form_contact" onSubmit={handleContact}>
          <p className="title">{t("keep_in_touch")}</p>

          <label>
            <input
              required
              placeholder={t("name_user")}
              type="text"
              name="name_contact"
              value={contact.name_contact}
              onChange={handleChange}
            />
            <FaUser />
          </label>

          <label>
            <input
              required
              placeholder={t("email")}
              type="email"
              name="email_contact"
              value={contact.email_contact}
              onChange={handleChange}
            />
            <MdEmail />
          </label>

          <label>
            <input
              required
              placeholder={t("title_message")}
              type="text"
              name="title_contact"
              value={contact.title_contact}
              onChange={handleChange}
            />
            <MdMessage />
          </label>

          <textarea
            name="message_contact"
            id="message_contact"
            placeholder={t("message")}
            value={contact.message_contact}
            onChange={handleChange}
          ></textarea>

          <button
            className="submit"
            disabled={isDisabledSubmit}
            style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
          >
            {t("submit")}
          </button>
        </form>

        <div className="social_media">
          <SocialMediaLinks social_media={social_media} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
