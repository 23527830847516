import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./register/register.css";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { useAppContext } from "../../components/context/AppProvider";
import Cookies from "js-cookie";

const ActivationCode = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const authUser = useAppContext();

  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const handleAddLogin = async () => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);

    Object.entries(authUser.auth).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    const response = await fetch(`${LinkLikeBachend}read/login.php`, {
      method: "POST",
      body: FormDataSubmit,
    });

    const resultText = await response.text();
    const [resultSuccess, token] = resultText.split("|");

    if (response.ok) {
      if (resultSuccess == "successfully") {
        toast.success(t("signin_success"));

        authUser.handleSetAuth({
          email: "",
          password: "",
        });

        setDisabledSubmit(false);

        Cookies.set("successfully", "true", { expires: 1 });
        Cookies.set("token", token, { expires: 1 });

        const redirectFrom = sessionStorage.getItem("redirectFrom");

        if (redirectFrom) {
          navigate(redirectFrom, { replace: true });
        } else {
          navigate("/home", { replace: true });
        }
      } else {
        toast.error(t("signin_error"));

        console.log(resultText);
        setDisabledSubmit(false);
        navigate("/login", { replace: true });
      }
    } else {
      const errorText = await response.text();
      toast.error(t("signin_error"));
      console.log("حدث خطأ:", errorText);
      setDisabledSubmit(false);
      navigate("/login", { replace: true });
    }
  };

  const handleAccountActivated = async (e) => {
    e.preventDefault();

    if (otp !== "" && otp.length == 5) {
      setDisabledSubmit(true);

      const FormDataSubmit = new FormData();
      FormDataSubmit.append("validation", validation);
      FormDataSubmit.append("otp", otp);

      const response = await fetch(
        `${LinkLikeBachend}read/activation_code.php`,
        {
          method: "POST",
          body: FormDataSubmit,
        }
      );

      if (response.ok) {
        try {
          const resultData = await response.text();
          if (resultData == "token_incorrect") {
            toast.error(t("confirmation_number_incorrect"));

            setDisabledSubmit(false);
          } else if (resultData === "successfully") {
            toast.success(t("account_been_confirmed_successfully"));

            setDisabledSubmit(false);

            handleAddLogin();
          } else {
            toast.error(t("confirmation_number_incorrect"));
            console.log("Unexpected response format:", resultData);
            setDisabledSubmit(false);
          }
        } catch (error) {
          toast.error(t("confirmation_number_incorrect"));
          console.error("Error:", error);
          setDisabledSubmit(false);
        }
      }
    } else {
      toast.error(t("activation_code_empty"));
    }
  };

  //   console.log(phone);

  return (
    <form className="form" onSubmit={handleAccountActivated}>
      <p className="title">{t("activation_code")}</p>

      <label className="activation_code_div">
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={5}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
      </label>

      <button
        className="submit"
        disabled={isDisabledSubmit}
        style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
      >
        {t("submit")}
      </button>
      <p className="signin">
        <NavLink to="/login">{t("signin")}</NavLink>
      </p>
    </form>
  );
};

export default ActivationCode;
