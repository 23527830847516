import React, { useEffect, useState } from "react";
import "./payment.css";
import { IoCloseSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { MdOutlinePayment } from "react-icons/md";
import { BiSolidDiscount } from "react-icons/bi";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

const PaymentForm = ({ viewTours, account, close }) => {
  const [offer, setOffer] = useState([]);
  const [authPayment, setAuthPayment] = useState({
    username: "567620706",
    password: "**100200300**Mo",
  });

  const token = Cookies.get("token");
  const navigate = useNavigate();

  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [isDisabledOffer, setDisabledOffer] = useState(false);

  const { t } = useTranslation();

  const [numberTickets, setNumberTickets] = useState(1);
  const [discountUser, setDiscountUser] = useState("");

  const roundedPrice =
    parseInt(Math.ceil(Math.round(viewTours[0].ticket_price)), 10) * 100;

  const [ticketPrice, setTicketPrice] = useState(roundedPrice);

  const handleDiscountChange = (e) => {
    setDiscountUser(e.target.value);
  };

  const calculateAmount = () => {
    const baseAmount = ticketPrice * numberTickets;
    const additionalPercentage = 15 / 100; // 15%
    const additionalAmount = baseAmount * additionalPercentage;

    let totalAmount = baseAmount + additionalAmount;

    if (offer.length > 0) {
      const discountPercentage = offer[0].discount;
      const discountAmount = (totalAmount * discountPercentage) / 100;
      totalAmount -= discountAmount;
    }
    return Math.ceil(totalAmount);
  };

  let amountItem = calculateAmount() / numberTickets;

  const [dataPayment, setDataPayment] = useState({
    amount_cents: calculateAmount(),
    currency: "SAR",
    is_live: true,
    payment_methods: [3737],

    client_info: {
      first_name: account[0]?.name_user.split(" ")[0],
      last_name: account[0]?.name_user.split(" ")[1],
      email: account[0]?.email,
    },
    items: [
      {
        name: viewTours[0].title_en,
        amount: amountItem,
        description: viewTours[0].title_en,
        quantity: numberTickets,
      },
    ],
  });

  const handleClose = () => {
    close(false);
  };

  const handleIncrement = () => {
    setNumberTickets((prevNumberTickets) => Math.max(1, prevNumberTickets + 1));
  };

  const handleDecrement = () => {
    setNumberTickets((prevNumberTickets) => Math.max(1, prevNumberTickets - 1));
  };

  const handleNumberTickets = (e) => {
    const value = parseInt(e.target.value, 10);

    setNumberTickets(value);
    setTicketPrice(roundedPrice * value);
  };

  useEffect(() => {
    setDataPayment((prevData) => ({
      ...prevData,
      amount_cents: calculateAmount(),
      items: [
        {
          ...prevData.items[0],
          quantity: numberTickets,
        },
      ],
    }));
  }, [ticketPrice, offer, numberTickets]);

  const handleDiscount = async (e) => {
    e.preventDefault();

    setDisabledOffer(true);

    if (discountUser !== offer[0]?.code_discount) {
      const formDiscount = new FormData();
      formDiscount.append("validation", validation);
      formDiscount.append("discount", discountUser);

      try {
        const responseUser = await fetch(
          `${LinkLikeBachend}read/discount.php`,
          {
            method: "POST",
            body: formDiscount,
          }
        );
        const discount = await responseUser.json();

        setDiscountUser("");

        if (discount.no_offer == "no_offer") {
          toast.error(t("no_discount_code_found"));
          setDisabledOffer(false);
        } else {
          setOffer(discount);

          toast.success(`${t("has_been_discount")} (${discount[0].discount}%)`);

          setDisabledOffer(true);
        }
      } catch (error) {
        console.error("Error:", error);
        setDisabledOffer(false);
      }
    } else {
      toast.error(t("discount_code_used"));
      setDisabledSubmit(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setDisabledSubmit(true);

    try {
      const response = await fetch("https://ksa.paymob.com/api/auth/tokens", {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify(authPayment),
      });

      if (!response.ok) {
        const responseBody = await response.json();
        setDisabledSubmit(false);
        return console.log(responseBody);
      }

      const dataToken = await response.json();

      handlePay(dataToken.token);
    } catch (error) {
      console.error("Error:", error);
      setDisabledSubmit(false);
    }
  };

  const handlePay = async (dataToken) => {
    try {
      const responseOrder = await fetch(
        "https://ksa.paymob.com/api/ecommerce/payment-links",
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            Authorization: `Bearer ${dataToken}`,
          },
          body: JSON.stringify(dataPayment),
        }
      );

      if (!responseOrder.ok) {
        const responseOrderBody = await responseOrder.json();
        setDisabledSubmit(false);
        return console.log(responseOrderBody);
      }

      const data = await responseOrder.json();

      handleCreatePay(data);
    } catch (error) {
      console.error("Error:", error);
      setDisabledSubmit(false);
    }
  };

  const handleCreatePay = async (data) => {
    const formPayment = new FormData();
    formPayment.append("validation", validation);
    formPayment.append("token", token);
    formPayment.append("number_tickets", numberTickets);
    formPayment.append("id_payment", data.order);
    var jsonData = JSON.stringify(dataPayment);

    formPayment.append("payment_information", jsonData);
    formPayment.append("id_tours", viewTours[0]?.id);
    formPayment.append("id_offer", offer[0]?.id);

    try {
      const responseUser = await fetch(
        `${LinkLikeBachend}create/buy_tickets.php`,
        {
          method: "POST",
          body: formPayment,
        }
      );
      const buyTickets = await responseUser.text();

      if (buyTickets == "successfully") {
        // console.log(buyTickets);
        setDisabledSubmit(false);

        window.location.href = data.client_url;
      } else if (buyTickets == "not_token") {
        Cookies.remove("successfully");
        Cookies.remove("token");

        navigate("/account");

        toast.error(t("purchase_status_error"));
        console.log(buyTickets);
        setDisabledOffer(false);
      } else {
        setDisabledOffer(false);
        console.error("Error:", buyTickets);
      }

      setDisabledSubmit(false);
    } catch (error) {
      setDisabledOffer(false);
      console.error("Error:", error);
    }
  };

  // console.log(viewTours);
  // console.log(numberTickets);
  // console.log(dataPayment);
  // console.log(offer);
  // console.log(account);

  return (
    <div className="payment_form">
      <IoCloseSharp className="IoCloseSharp" onClick={handleClose} />
      <div className="containt_payment_form">
        <div className="containt_info">
          <div className="ticket_price">
            <p>{t("coun_ticket")}</p>

            <div className="number_ticket">
              <div onClick={handleDecrement} className="dncrement">
                -
              </div>

              <input
                type="text"
                name="number_ticket"
                id="number_ticket"
                required
                readOnly
                value={numberTickets}
                onChange={handleNumberTickets}
              />

              <div onClick={handleIncrement} className="increment">
                +
              </div>
            </div>
          </div>
          <div className="div_ticket_p">
            <p>
              {t("ticket_price")} <small>{ticketPrice / 100}</small>{" "}
              {t("saudi_riyal")}
            </p>
            <p>
              <small>15%</small> {t("taxation")}
            </p>
            <p>
              {t("total")} <small>{dataPayment.amount_cents / 100}</small>{" "}
              {t("saudi_riyal")}
            </p>
          </div>
          <form
            method="POST"
            className="offer_discount"
            onSubmit={handleDiscount}
          >
            <div className="input-group discount">
              <label>{t("discount")}</label>
              <input
                type="text"
                name="discount"
                value={discountUser}
                onChange={handleDiscountChange}
                placeholder={t("code_discount")}
                required
              />
            </div>

            <button
              type="submit"
              disabled={isDisabledOffer}
              style={{
                background: isDisabledOffer ? "green" : "",
                opacity: isDisabledOffer ? "0.3" : "",
              }}
            >
              {t("discount_application")} <BiSolidDiscount />
            </button>
          </form>
          <div className="form_credit_card_full">
            <p>{t("checkout_process_start")}</p>
            <div className="form_credit_card">
              <form
                method="POST"
                className="credit_card"
                onSubmit={handleSubmit}
              >
                <button
                  type="submit"
                  disabled={isDisabledSubmit}
                  style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
                >
                  {t("pay")} <MdOutlinePayment />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
