import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const UpdateUser = () => {
  const { idUser } = useParams();
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const [accountConfirmation, setAccountConfirmation] = useState(0);

  const [allDataUser, setAllDataUser] = useState([]);
  const [updateUser, setUpdateUser] = useState({
    name_user: "",
    phone: "",
    country: "",
    age: "",
    email: "",
    account_confirmation: 0,
  });

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataUser = async () => {
    const formDataViewUser = new FormData();
    formDataViewUser.append("validation", validation);
    formDataViewUser.append("idUser", idUser);

    try {
      const responseUser = await fetch(`${LinkLikeBachend}read/user.php`, {
        method: "POST",
        body: formDataViewUser,
      });
      const dataUser = await responseUser.json();
      setAllDataUser(dataUser);

      setUpdateUser({
        name_user: dataUser[0].name_user,
        phone: dataUser[0].phone,
        country: dataUser[0].country,
        age: dataUser[0].age,
        email: dataUser[0].email,
      });

      setAccountConfirmation(dataUser[0].account_confirmation);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataUser();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeConfirmation = (event) => {
    setAccountConfirmation(event.target.checked ? 1 : 0);
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();

    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("account_confirmation", accountConfirmation);
    FormDataSubmit.append("idUser", idUser);

    Object.entries(updateUser).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    const response = await fetch(`${LinkLikeBachend}update/update_user.php`, {
      method: "POST",
      body: FormDataSubmit,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("تم التحديث بنجاح");

        setDisabledSubmit(false);

        fetchDataUser();
      } else {
        toast.error("حدث خطأ عند تحديث البيانات");

        console.log(resultText);
        setDisabledSubmit(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("حدث خطأ عند تحديث البيانات");
      console.log("حدث خطأ:", errorText);
      setDisabledSubmit(false);
    }
  };

  // console.log(allDataUser);
  // console.log(updateUser);
  // console.log(accountConfirmation);

  return (
    <div>
      <form className="form" onSubmit={handleUpdateUser}>
        <p className="title">تحديث مستخدم</p>
        <label>
          <input
            required
            placeholder=""
            type="text"
            name="name_user"
            className="input"
            value={updateUser.name_user}
            onChange={handleChange}
          />
          <span>الاسم</span>
        </label>

        <label>
          <input
            required
            placeholder=""
            name="phone"
            type="number"
            className="input"
            value={updateUser.phone}
            onChange={handleChange}
          />
          <span>رقم الهاتف</span>
        </label>

        <label>
          <input
            required
            placeholder=""
            name="country"
            type="text"
            className="input"
            value={updateUser.country}
            onChange={handleChange}
          />
          <span>الدولة</span>
        </label>

        <label>
          <input
            required
            placeholder=""
            name="age"
            type="number"
            className="input"
            value={updateUser.age}
            onChange={handleChange}
          />
          <span>العمر</span>
        </label>

        <label>
          <input
            required
            placeholder=""
            name="email"
            type="email"
            className="input"
            value={updateUser.email}
            onChange={handleChange}
          />
          <span>البريد</span>
        </label>

        <div className="flex">
          <label>
            <input
              name="account_confirmation"
              type="checkbox"
              checked={accountConfirmation == 1}
              onChange={handleChangeConfirmation}
            />
          </label>

          <span>حالة الحساب</span>
        </div>

        <button
          className="submit"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
        >
          {isDisabledSubmit ? "انتظر" : "تحديث"}
        </button>
      </form>
    </div>
  );
};

export default UpdateUser;
