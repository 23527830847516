import React, { useEffect, useState } from "react";
import "./adminUser.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AdminUser = () => {
  const [viewUser, setViewUser] = useState([]);
  const [UserCount, setUserCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const [searchUser, setSearchUser] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataUser = async () => {
    const formDataUser = new FormData();
    formDataUser.append("validation", validation);

    try {
      const responseUser = await fetch(`${LinkLikeBachend}read/user.php`, {
        method: "POST",
        body: formDataUser,
      });
      const dataUser = await responseUser.json();
      setViewUser(dataUser);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDataUserCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);

      const responseUserCount = await fetch(
        `${LinkLikeBachend}read/user_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataUserCount = await responseUserCount.text();

      setUserCount(dataUserCount);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewUserPage = new FormData();
    formDataViewUserPage.append("validation", validation);
    formDataViewUserPage.append("page", currentPage + 1);

    try {
      const responseUser = await fetch(`${LinkLikeBachend}read/user.php`, {
        method: "POST",
        body: formDataViewUserPage,
      });
      const dataUser = await responseUser.json();

      setTimeout(() => {
        setViewUser((prevData) => prevData.concat(dataUser));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataUser();
    fetchDataUserCount();
  }, []);

  const handleDelete = async (idUser) => {
    const FormDataUser = new FormData();
    FormDataUser.append("validation", validation);
    FormDataUser.append("idUser", idUser);

    const response = await fetch(`${LinkLikeBachend}delete/delete_user.php`, {
      method: "POST",
      body: FormDataUser,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("تم الحذف بنجاح");

        fetchDataUser();
        fetchDataUserCount();
      } else {
        toast.error("حدث خطأ عند الحذف");

        console.log(resultText);
      }
    } else {
      const errorText = await response.text();
      toast.error("حدث خطأ عند الحذف");
      console.log("حدث خطأ:", errorText);
    }
  };

  const handleSearchChange = (e) => {
    setSearchUser(e.target.value);
  };

  const handleUpdate = (idUser) => {
    navigate(`/dashboard/update-user/${idUser}`);
  };

  const handleUpdateUser = () => {
    if (searchUser.length > 0) {
      setDisabledSubmit(true);

      navigate(`/dashboard/search-user/${searchUser}`);
    } else {
      toast.success("حقل البحث فارغ");
    }
  };

  // console.log(User);
  // console.log(viewUser);
  // console.log(UserCount);

  return (
    <>
      <div className="form form_search">
        <p className="title">بحث عن مستخدم</p>

        <label>
          <input
            placeholder=""
            name="search"
            type="search"
            className="input"
            value={searchUser || ""}
            onChange={handleSearchChange}
          />
          <span>رقم الهاتف</span>
        </label>

        <button
          className="submit"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
          onClick={handleUpdateUser}
        >
          بحث
        </button>
      </div>

      <div className="div_load_more">
        <h2>المستخدمين</h2>
      </div>

      <div className="viewUser">
        {viewUser ? (
          viewUser.length > 0 &&
          viewUser.map((item, i) => (
            <div className="User_item" key={i}>
              <p>الاسم: {item.name_user}</p>
              <p>رقم الهاتف: {item.phone}</p>
              <p>الدولة: {item.country}</p>
              <p>العمر: {item.age}</p>
              <p>البريد: {item.email}</p>
              <p>
                {item.account_confirmation == "1" ? (
                  <span>حالة الحساب: مفعل</span>
                ) : (
                  <span>حالة الحساب: غير مفعل</span>
                )}
              </p>
              <p>تاريخ التسجيل: {item.date_registration}</p>

              <button onClick={() => handleDelete(item.id)}>حذف</button>
              <button onClick={() => handleUpdate(item.id)}>تحديث</button>
            </div>
          ))
        ) : (
          <p>لا يوجد بيانات لعرضها</p>
        )}
      </div>

      <div className="div_load_more">
        {viewUser &&
          viewUser.length > 0 &&
          parseInt(UserCount) !== viewUser.length && (
            <button
              className="button load_more"
              onClick={handleLoadMore}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              {!isDisabledLoadMore ? "المزيد" : "تحميل..."}
            </button>
          )}
      </div>
    </>
  );
};

export default AdminUser;
