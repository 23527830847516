import React, { useState } from "react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import "./adminChangePassword.css";

const AdminChangePassword = () => {
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const [password, setPassword] = useState("");
  const [isDisabledLogin, setDisabledLogin] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setDisabledLogin(true);

    const formDataLogin = new FormData();
    formDataLogin.append("validation", validation);
    formDataLogin.append("password_admin", password);

    const response = await fetch(
      `${LinkLikeBachend}update/admin_change_password.php`,
      {
        method: "POST",
        body: formDataLogin,
      }
    );

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("تم تغيير كلمة المرور بنجاح");

        setPassword("");

        Cookies.set("validation_login", "true");
      } else {
        toast.error("حدث خطأ عند تغيير كلمة المرور");
        console.log(resultText);
        setDisabledLogin(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("حدث خطأ عند تغيير كلمة المرور");
      console.log("حدث خطأ:", errorText);
      setDisabledLogin(false);
    }
  };

  return (
    <div className="login_admin admin-change-password" onSubmit={handleSubmit}>
      <div className="box">
        <span className="borderLine"></span>
        <form>
          <h2>تغيير كلمة المرور</h2>

          <div className="inputBox">
            <input
              type="password"
              required
              value={password}
              onChange={handlePasswordChange}
            />
            <span>كلمة المرور الجديده</span>
            <i></i>
          </div>

          <input
            type="submit"
            style={{ opacity: isDisabledLogin ? 0.3 : 1 }}
            value={isDisabledLogin ? "انتظار" : "حفظ"}
            disabled={isDisabledLogin}
          />
        </form>
      </div>
    </div>
  );
};

export default AdminChangePassword;
