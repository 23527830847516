import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MinutesToHoursFormat from "../viewPurchase/MinutesToHoursFormat";
import "./ticketReferral.css";
import WatchTour from "../../../components/watchTour/WatchTour";

const TicketReferral = () => {
  const token = Cookies.get("token");

  const [allPurchaseHistory, setPurchaseHistory] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [isWatchTour, setWatchTour] = useState(false);
  const [link, setLink] = useState("");
  const [time, setTime] = useState("");
  const [ticketsId, setTicketsId] = useState("");
  const [index, setIndex] = useState("");

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const { t } = useTranslation();

  const fetchDataAccount = async () => {
    const formDataAccount = new FormData();
    formDataAccount.append("validation", validation);
    formDataAccount.append("token", token);

    try {
      const responseSliderProducts = await fetch(
        `${LinkLikeBachend}read/view_user_account.php`,
        {
          method: "POST",
          body: formDataAccount,
        }
      );
      const dataSliderProducts = await responseSliderProducts.json();
      setUserEmail(dataSliderProducts[0].email);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchTicketReferral = async () => {
    const formTicketReferral = new FormData();
    formTicketReferral.append("validation", validation);
    formTicketReferral.append("userEmail", userEmail);

    try {
      const responseTicketReferral = await fetch(
        `${LinkLikeBachend}read/search_ticket_referral.php`,
        {
          method: "POST",
          body: formTicketReferral,
        }
      );
      const dataTicketReferral = await responseTicketReferral.json();

      setPurchaseHistory(dataTicketReferral);
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };

  let loadPage = useRef(true);

  useEffect(() => {
    fetchDataAccount();
    fetchTicketReferral();

    if (loadPage.current) {
      loadPage.current = false;
      fetchTicketReferral();
    }
  }, [userEmail, loadPage.current]);

  const handelLinkWatchTour = (link, time, ticketsId, index) => {
    setLink(link);
    setTime(time);
    setTicketsId(ticketsId);
    setIndex(index);

    setWatchTour(true);
  };

  // console.log(userEmail);
  // console.log(allPurchaseHistory);
  // console.log(loadPage.current);

  return (
    <>
      {isWatchTour && (
        <WatchTour
          close={setWatchTour}
          link={link}
          time={time}
          ticketsId={ticketsId}
          index={index}
          loadPage={loadPage}
        />
      )}
      <div className="view_purchase_full">
        {allPurchaseHistory && allPurchaseHistory.length > 0 ? (
          allPurchaseHistory?.map((item, index) => {
            let dataArray = "";

            if (
              item.referral_tickets_tour != "" &&
              item.referral_tickets_tour != null
            ) {
              const referral_tickets_tour = JSON.parse(
                item.referral_tickets_tour
              );
              dataArray = Object.keys(referral_tickets_tour).map(
                (key) => referral_tickets_tour[key]
              );
            }

            return (
              <div className="view_purchase_ref" key={index}>
                <div className="payment_information">
                  {dataArray &&
                    dataArray.length > 0 &&
                    dataArray[0].email != userEmail &&
                    dataArray.map((ref, i) => (
                      <React.StrictMode key={i}>
                        {ref.email == userEmail && (
                          <div className="div_payment_information">
                            {userEmail == ref.email ? (
                              <></>
                            ) : (
                              <b>
                                {t("invitation_mail")}:{" "}
                                <small>{ref.email}</small>
                              </b>
                            )}

                            {ref.isWatched == 0 ? (
                              <>
                                <b>
                                  {t("viewing_status")}:{" "}
                                  <small style={{ color: "orange" }}>
                                    {t("waiting")}
                                  </small>
                                </b>

                                {userEmail == ref.email ? (
                                  <b>
                                    {t("link_watch_tour")}:{" "}
                                    <small>
                                      <button
                                        onClick={() =>
                                          handelLinkWatchTour(
                                            item.link_watch_tour,
                                            ref.time,
                                            item.id,
                                            i
                                          )
                                        }
                                      >
                                        {t("watch")}
                                      </button>
                                    </small>
                                  </b>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                {ref.time == 0 ? (
                                  <b>
                                    {t("viewing_status")}:{" "}
                                    <small style={{ color: "red" }}>
                                      {t("watched")}
                                    </small>
                                  </b>
                                ) : (
                                  <>
                                    <b>
                                      {t("viewing_status")}:{" "}
                                      <small style={{ color: "green" }}>
                                        {t("watches")}
                                      </small>
                                    </b>

                                    {userEmail == ref.email ? (
                                      <b>
                                        {t("link_watch_tour")}:{" "}
                                        <small>
                                          <button
                                            onClick={() =>
                                              handelLinkWatchTour(
                                                item.link_watch_tour,
                                                ref.time,
                                                item.id,
                                                i
                                              )
                                            }
                                          >
                                            {t("watch")}
                                          </button>
                                        </small>
                                      </b>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}

                            <b>
                              {t("watch_time")}:{" "}
                              <small>
                                <MinutesToHoursFormat minutes={ref.time} />
                              </small>
                            </b>
                          </div>
                        )}
                      </React.StrictMode>
                    ))}
                </div>
              </div>
            );
          })
        ) : (
          <p>{t("no_data")}</p>
        )}
      </div>
    </>
  );
};

export default TicketReferral;
