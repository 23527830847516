import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "./register/register.css";
import ar from "react-phone-input-2/lang/ar.json";
import fr from "react-phone-input-2/lang/fr.json";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AccountActivated = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;
  let localization;

  if (currentLanguage === "ar") {
    localization = ar;
  } else if (currentLanguage === "fr") {
    localization = fr;
  }

  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const [phone, setPhone] = useState("");

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhone(value);
  };

  const handleMailActivated = async (phoneN) => {
    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("phone", phoneN);

    const response = await fetch(`${LinkLikeBachend}read/send_mail.php`, {
      method: "POST",
      body: FormDataSubmit,
    });

    if (response.ok) {
      try {
        const resultData = await response.text();
        console.log(resultData);
      } catch (error) {
        console.error("Error :", error);
      }
    }
  };

  const handleAccountActivated = async (e) => {
    e.preventDefault();

    if (phone !== "") {
      setDisabledSubmit(true);

      const FormDataSubmit = new FormData();
      FormDataSubmit.append("validation", validation);
      FormDataSubmit.append("phone", phone);

      const response = await fetch(
        `${LinkLikeBachend}read/whatsapp_verification.php`,
        {
          method: "POST",
          body: FormDataSubmit,
        }
      );

      if (response.ok) {
        try {
          const resultData = await response.json();
          if (resultData.message == "phone_incorrect") {
            toast.error(t("AccountActivated_phone_incorrect"));

            setDisabledSubmit(false);
          } else if (resultData.status === "success") {
            toast.success(t("AccountActivated_success"));

            setDisabledSubmit(false);

            handleMailActivated(phone);

            navigate("/activation-code", { replace: true });
          } else if (resultData.status === "error") {
            toast.error(t("AccountActivated_error"));

            console.log(resultData);
            setDisabledSubmit(false);
          } else {
            toast.error(t("AccountActivated_error"));
            console.log("Unexpected response format:", resultData);
            setDisabledSubmit(false);
          }
        } catch (error) {
          toast.error(t("AccountActivated_error"));
          console.error("Error parsing JSON:", error);
          setDisabledSubmit(false);
        }
      }
    } else {
      toast.error(t("phone_empty"));
    }
  };

  //   console.log(phone);

  return (
    <form className="form" onSubmit={handleAccountActivated}>
      <p className="title">{t("activate_account")}</p>

      <label style={{ direction: "ltr" }}>
        <PhoneInput
          placeholder=""
          country={"sa"}
          localization={localization}
          value={phone}
          onChange={handlePhoneChange}
          inputProps={{
            required: true,
          }}
        />
      </label>

      <button
        className="submit"
        disabled={isDisabledSubmit}
        style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
      >
        {t("submit")}
      </button>
      <p className="signin">
        <NavLink to="/login">{t("signin")}</NavLink>
      </p>
    </form>
  );
};

export default AccountActivated;
