import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "./register.css";
import ar from "react-phone-input-2/lang/ar.json";
import fr from "react-phone-input-2/lang/fr.json";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Register = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;
  let localization;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailRef = queryParams.get("email");

  if (currentLanguage === "ar") {
    localization = ar;
  } else if (currentLanguage === "fr") {
    localization = fr;
  }

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("Saudi Arabia");
  const [addRegister, setAddRegister] = useState({
    first_name: "",
    last_name: "",
    age: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    if (emailRef) {
      setAddRegister({
        first_name: "",
        last_name: "",
        age: "",
        email: emailRef,
        password: "",
        confirm_password: "",
      });
    }
  }, []);

  const handleShowPassword1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const handleShowPassword2 = () => {
    setShowPassword2((prevShowPassword) => !prevShowPassword);
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhone(value);
    setCountry(country);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddRegister((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAccountActivated = async (phoneN) => {
    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("phone", phoneN);

    const response = await fetch(
      `${LinkLikeBachend}read/whatsapp_verification.php`,
      {
        method: "POST",
        body: FormDataSubmit,
      }
    );

    if (response.ok) {
      try {
        const resultData = await response.json();
        if (resultData.status === "success") {
          toast.success(t("AccountActivated_success"));

          navigate("/activation-code");
        } else if (resultData.status === "error") {
          toast.error(t("AccountActivated_error"));

          console.log(resultData);
        } else {
          toast.error(t("register_error"));
          console.log("Unexpected response format:", resultData);
        }
      } catch (error) {
        toast.error(t("register_error"));
        console.error("Error parsing JSON:", error);
      }
    }
  };

  const handleMailActivated = async (phoneN) => {
    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("phone", phoneN);

    const response = await fetch(`${LinkLikeBachend}read/send_mail.php`, {
      method: "POST",
      body: FormDataSubmit,
    });

    if (response.ok) {
      try {
        const resultData = await response.text();
        console.log(resultData);
      } catch (error) {
        console.error("Error :", error);
      }
    }
  };

  const handleAddRegister = async (e) => {
    e.preventDefault();

    if (phone !== "") {
      if (addRegister.password == addRegister.confirm_password) {
        setDisabledSubmit(true);

        const FormDataSubmit = new FormData();
        FormDataSubmit.append("validation", validation);
        FormDataSubmit.append("phone", phone);
        FormDataSubmit.append("country", country.name);

        Object.entries(addRegister).forEach(([key, value]) => {
          FormDataSubmit.append(key, value);
        });

        const response = await fetch(`${LinkLikeBachend}create/register.php`, {
          method: "POST",
          body: FormDataSubmit,
        });

        const resultText = await response.text();

        const [resultSuccess, phoneN] = resultText.split("|");

        if (response.ok) {
          if (resultSuccess == "successfully") {
            toast.success(t("register_success"));

            setAddRegister({
              first_name: "",
              last_name: "",
              age: "",
              email: "",
              password: "",
              confirm_password: "",
            });

            setPhone("");
            setCountry("Saudi Arabia");

            setDisabledSubmit(false);

            handleAccountActivated(phoneN);
            handleMailActivated(phoneN);
          } else if (resultText == "Phone_already") {
            toast.error(t("register_Phone_already"));

            setDisabledSubmit(false);
          } else if (resultText == "email_already") {
            toast.error(t("register_email_already"));

            setDisabledSubmit(false);
          } else {
            toast.error(t("register_error"));

            console.log(resultText);
            setDisabledSubmit(false);
          }
        } else {
          const errorText = await response.text();
          toast.error(t("register_error"));
          console.log("حدث خطأ:", errorText);
          setDisabledSubmit(false);
        }
      } else {
        toast.error(t("password_match"));
        setDisabledSubmit(false);
      }
    } else {
      toast.error(t("phone_empty"));
      setDisabledSubmit(false);
    }
  };

  // console.log(currentLanguage);
  // console.log(phone);
  // console.log(country.name);
  // console.log(addRegister);

  return (
    <form className="form" onSubmit={handleAddRegister}>
      <p className="title">{t("register")}</p>
      <div className="flex">
        <label>
          <input
            required
            placeholder=""
            type="text"
            name="first_name"
            className="input"
            value={addRegister.first_name}
            onChange={handleChange}
          />
          <span>{t("first_name")}</span>
        </label>

        <label>
          <input
            required
            placeholder=""
            name="last_name"
            type="text"
            className="input"
            value={addRegister.last_name}
            onChange={handleChange}
          />
          <span>{t("last_name")}</span>
        </label>
      </div>

      <label>
        <input
          required
          placeholder=""
          name="age"
          type="number"
          className="input"
          value={addRegister.age}
          onChange={handleChange}
        />
        <span>{t("age")}</span>
      </label>

      <label style={{ direction: "ltr" }}>
        <PhoneInput
          placeholder=""
          country={"sa"}
          localization={localization}
          value={phone}
          onChange={handlePhoneChange}
          inputProps={{
            required: true,
          }}
        />
      </label>

      <label>
        <input
          required
          placeholder=""
          name="email"
          type="email"
          className="input"
          value={addRegister.email}
          onChange={handleChange}
        />
        <span>{t("email")}</span>
      </label>

      <label>
        <input
          required
          placeholder=""
          name="password"
          type={showPassword1 ? "text" : "password"}
          className="input"
          value={addRegister.password}
          onChange={handleChange}
        />
        <span>{t("password")}</span>

        {showPassword1 ? (
          <FaEyeSlash className="faeye_show" onClick={handleShowPassword1} />
        ) : (
          <FaEye className="faeye_show" onClick={handleShowPassword1} />
        )}
      </label>

      <label>
        <input
          required
          placeholder=""
          type={showPassword2 ? "text" : "password"}
          name="confirm_password"
          className="input"
          value={addRegister.confirm_password}
          onChange={handleChange}
        />
        <span>{t("confirm_password")}</span>

        {showPassword2 ? (
          <FaEyeSlash className="faeye_show" onClick={handleShowPassword2} />
        ) : (
          <FaEye className="faeye_show" onClick={handleShowPassword2} />
        )}
      </label>

      <button
        className="submit"
        disabled={isDisabledSubmit}
        style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
      >
        {t("submit")}
      </button>
      <p className="signin">
        {t("already_account")} <NavLink to="/login">{t("signin")}</NavLink>
      </p>
    </form>
  );
};

export default Register;
