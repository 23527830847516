import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./register/register.css";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [addChangePassword, setAddChangePassword] = useState({
    password: "",
    confirm_password: "",
  });

  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const handleShowPassword1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const handleShowPassword2 = () => {
    setShowPassword2((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddChangePassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAccountActivated = async (e) => {
    e.preventDefault();

    if (otp !== "" && otp.length == 5) {
      if (addChangePassword.password == addChangePassword.confirm_password) {
        setDisabledSubmit(true);

        const FormDataSubmit = new FormData();

        Object.entries(addChangePassword).forEach(([key, value]) => {
          FormDataSubmit.append(key, value);
        });

        FormDataSubmit.append("validation", validation);
        FormDataSubmit.append("otp", otp);
        FormDataSubmit.append("password", addChangePassword.password);

        const response = await fetch(
          `${LinkLikeBachend}update/change_new_password.php`,
          {
            method: "POST",
            body: FormDataSubmit,
          }
        );

        if (response.ok) {
          try {
            const resultData = await response.text();
            if (resultData == "token_incorrect") {
              toast.error(t("confirmation_number_incorrect"));

              setDisabledSubmit(false);
            } else if (resultData === "successfully") {
              toast.success(t("password_been_changed"));

              setDisabledSubmit(false);

              navigate("/login", { replace: true });
            } else {
              toast.error(t("confirmation_number_incorrect"));
              console.log("Unexpected response format:", resultData);
              setDisabledSubmit(false);
            }
          } catch (error) {
            toast.error(t("confirmation_number_incorrect"));
            console.error("Error:", error);
            setDisabledSubmit(false);
          }
        }
      } else {
        toast.error(t("password_match"));
        setDisabledSubmit(false);
      }
    } else {
      toast.error(t("phone_empty"));
      setDisabledSubmit(false);
    }
  };

  //   console.log(phone);

  return (
    <form className="form" onSubmit={handleAccountActivated}>
      <p className="title">{t("activation_code")}</p>

      <label className="activation_code_div">
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={5}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
      </label>

      <label>
        <input
          required
          placeholder=""
          name="password"
          type={showPassword1 ? "text" : "password"}
          className="input"
          value={addChangePassword.password}
          onChange={handleChange}
        />
        <span>{t("new_password")}</span>

        {showPassword1 ? (
          <FaEyeSlash className="faeye_show" onClick={handleShowPassword1} />
        ) : (
          <FaEye className="faeye_show" onClick={handleShowPassword1} />
        )}
      </label>

      <label>
        <input
          required
          placeholder=""
          type={showPassword2 ? "text" : "password"}
          name="confirm_password"
          className="input"
          value={addChangePassword.confirm_password}
          onChange={handleChange}
        />
        <span>{t("retype_new_password")}</span>

        {showPassword2 ? (
          <FaEyeSlash className="faeye_show" onClick={handleShowPassword2} />
        ) : (
          <FaEye className="faeye_show" onClick={handleShowPassword2} />
        )}
      </label>

      <button
        className="submit"
        disabled={isDisabledSubmit}
        style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
      >
        {t("submit")}
      </button>
      <p className="signin">
        <NavLink to="/login">{t("signin")}</NavLink>
      </p>
    </form>
  );
};

export default ChangePassword;
