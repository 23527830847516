import React, { useEffect, useState } from "react";
import "./navbar.css";
import logo from "../../images/logo.webp";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "./../footer/Footer";
import Icon from "./icon/Icon";
import Cookies from "js-cookie";
import SocialMediaLinks from "../socialMediaLinks/SocialMediaLinks";
import { useAppContext } from "../context/AppProvider";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";

const Navbar = () => {
  const location = useLocation();
  const [isChecked, setChecked] = useState(false);
  const navigate = useNavigate();
  const successfullyCookieValue = Cookies.get("successfully");
  const tokenCookieValue = Cookies.get("token");
  const [social_media, setSocial_media] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const [subLinksShow, setSubLinksShow] = useState(false);

  const { t } = useTranslation();
  const token = Cookies.get("token");
  const authUser = useAppContext();

  const [successUser, setSuccessUser] = useState(false);

  const fetchDataAccount = async () => {
    const formDataAccount = new FormData();
    formDataAccount.append("validation", validation);
    formDataAccount.append("token", token);

    try {
      const responseDataAccount = await fetch(
        `${LinkLikeBachend}read/view_user_account.php`,
        {
          method: "POST",
          body: formDataAccount,
        }
      );
      const dataDataAccount = await responseDataAccount.json();

      // console.log(dataDataAccount);
    } catch (error) {
      Cookies.remove("successfully");
      Cookies.remove("token");

      setSuccessUser(false);

      // console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataAccount();

    if (window.innerWidth <= 950) {
      setSubLinksShow(true);
    }

    if (successfullyCookieValue == "true" && tokenCookieValue) {
      setSuccessUser(true);

      authUser.handleSetAuth({
        email: "",
        password: "",
      });
    } else {
      setSuccessUser(false);
    }
  }, [successfullyCookieValue, tokenCookieValue]);

  const handleToggle = () => {
    setChecked(!isChecked);

    if (window.innerWidth >= 950) {
      setSubLinksShow(false);
    }
  };

  const links = [
    { to: "/home", label: t("home") },
    { to: "about", label: t("about_us") },
    { to: "tayebat-city-events", label: t("tayebatCityEvents") },
    { to: "store", label: t("store") },
  ];

  const subLinks = [
    { to: "contact-us", label: t("contact_us") },
    { to: "privacy-policy", label: t("privacy_policy") },
    { to: "how-to-use", label: t("howUse") },
  ];

  const fetchDataAbout = async () => {
    const formDataAbout = new FormData();
    formDataAbout.append("validation", validation);

    try {
      const responseDataAbout = await fetch(
        `${LinkLikeBachend}read/social_media.php`,
        {
          method: "POST",
          body: formDataAbout,
        }
      );
      const dataAbout = await responseDataAbout.json();
      setSocial_media(dataAbout);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataAbout();
  }, []);

  const handleSubLinksShow = () => {
    if (window.innerWidth >= 950) {
      setSubLinksShow(!subLinksShow);
    }
  };

  useEffect(() => {
    if (successfullyCookieValue == "true" && tokenCookieValue) {
      if (
        location.pathname === "/register" ||
        location.pathname === "/login" ||
        location.pathname === "/forgot-password" ||
        location.pathname === "/activation-code" ||
        location.pathname === "/change-password" ||
        location.pathname === "/account-activated"
      ) {
        navigate("/account", { replace: true });
      }
    } else {
      if (
        location.pathname === "/purchase-history" ||
        location.pathname === "/account"
      ) {
        navigate("/login", { replace: true });
      }
    }
  }, [navigate, location.pathname]);

  return (
    <>
      <nav>
        <input
          type="checkbox"
          id="nav-toggle"
          checked={isChecked}
          onChange={handleToggle}
        />
        <NavLink to="/" className="logo">
          <img src={logo} alt="logo" />
        </NavLink>

        <ul className="links" style={{ top: isChecked ? "10vh" : "100%" }}>
          {links.map((item, i) => (
            <li key={i}>
              {item.to === "store" ? (
                <a
                  href="https://store.altaybat-city.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.label}
                </a>
              ) : (
                <NavLink onClick={handleToggle} to={item.to}>
                  {item.label}
                </NavLink>
              )}
            </li>
          ))}

          <div className="full_links_page">
            <li className="links_page" onClick={handleSubLinksShow}>
              <a>
                {subLinksShow ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                {t("subLinksPage")}
              </a>
            </li>

            <div
              className="sub_links_page"
              onClick={handleSubLinksShow}
              style={{
                display: subLinksShow ? "block" : "none",
                direction: authUser.direction,
              }}
            >
              {subLinks.map((subitem, index) => (
                <li key={index}>
                  <NavLink to={subitem.to} onClick={handleToggle}>
                    {subitem.label}
                  </NavLink>
                </li>
              ))}
            </div>
          </div>

          {successUser && (
            <li>
              <NavLink onClick={handleToggle} to="/info-account">
                {t("account_nav")}
              </NavLink>
            </li>
          )}

          <li>
            <NavLink to="/" className="ChangeLang">
              {t("language")}
            </NavLink>
          </li>

          <div className="social_media">
            <SocialMediaLinks social_media={social_media} />
          </div>
        </ul>
        <Icon setChecked={setChecked} />

        <label htmlFor="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>

      <Outlet />
      <Footer />
    </>
  );
};

export default Navbar;
