import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./account.css";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from "js-cookie";

const Account = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = Cookies.get("token");

  const [showPassword1, setShowPassword1] = useState(false);
  const [newassword, setNewPassword] = useState("");

  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const [alldataAccount, setAllDataAccount] = useState([]);
  const [dataAccount, setDataAccount] = useState({
    name_user: "",
    phone: "",
    country: "",
    age: "",
    email: "",
    date_registration: "",
  });

  const fetchDataAccount = async () => {
    const formDataAccount = new FormData();
    formDataAccount.append("validation", validation);
    formDataAccount.append("token", token);

    try {
      const responseSliderProducts = await fetch(
        `${LinkLikeBachend}read/view_user_account.php`,
        {
          method: "POST",
          body: formDataAccount,
        }
      );
      const dataSliderProducts = await responseSliderProducts.json();
      setAllDataAccount(dataSliderProducts);
      setDataAccount({
        name_user: dataSliderProducts[0].name_user,
        phone: dataSliderProducts[0].phone,
        country: dataSliderProducts[0].country,
        age: dataSliderProducts[0].age,
        email: dataSliderProducts[0].email,
        date_registration: dataSliderProducts[0].date_registration,
      });
    } catch (error) {
      Cookies.remove("successfully");
      Cookies.remove("token");

      navigate("/login", { replace: true });

      // console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataAccount();
  }, []);

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleShowPassword1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const handleupdatePassword = async (e) => {
    e.preventDefault();

    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("token", token);
    FormDataSubmit.append("newassword", newassword);

    const response = await fetch(
      `${LinkLikeBachend}update/account_change_password.php`,
      {
        method: "POST",
        body: FormDataSubmit,
      }
    );

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success(t("password_been_changed"));

        setDisabledSubmit(false);

        setNewPassword("");
      } else {
        toast.error(t("password_error_changed"));

        console.log(resultText);
        setDisabledSubmit(false);
      }
    } else {
      const errorText = await response.text();
      toast.error(t("password_error_changed"));
      console.log("حدث خطأ:", errorText);
      setDisabledSubmit(false);
    }
  };

  // console.log(newassword);
  // console.log(alldataAccount);
  // console.log(dataAccount);
  // console.log(token);

  return (
    <div className="form">
      <p className="title">{t("account_information")}</p>

      <div className="flex">
        <label>
          <span className="h2_span">{t("name_user")}</span>
          <h2 className="input">{dataAccount.name_user}</h2>
        </label>
      </div>

      <label>
        <span className="h2_span">{t("phone")}</span>
        <h2 className="input">{dataAccount.phone}</h2>
      </label>

      <label>
        <span className="h2_span">{t("country")}</span>
        <h2 className="input">{dataAccount.country}</h2>
      </label>

      <label>
        <span className="h2_span">{t("age")}</span>
        <h2 className="input">{dataAccount.age}</h2>
      </label>

      <label>
        <span className="h2_span">{t("email")}</span>
        <h2 className="input">{dataAccount.email}</h2>
      </label>

      <label>
        <span className="h2_span">{t("date_registration")}</span>
        <h2 className="input">{dataAccount.date_registration}</h2>
      </label>

      <form
        className="form"
        onSubmit={handleupdatePassword}
        style={{ margin: "0" }}
      >
        <label>
          <input
            required
            placeholder=""
            name="password"
            type={showPassword1 ? "text" : "password"}
            className="input"
            value={newassword}
            onChange={handleNewPassword}
          />
          <span>{t("change_password")}</span>

          {showPassword1 ? (
            <FaEyeSlash className="faeye_show" onClick={handleShowPassword1} />
          ) : (
            <FaEye className="faeye_show" onClick={handleShowPassword1} />
          )}
        </label>

        <button
          className="submit"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
        >
          {t("seve")}
        </button>
      </form>
    </div>
  );
};

export default Account;
