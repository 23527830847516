import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./register/register.css";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from "js-cookie";
import { useAppContext } from "../../components/context/AppProvider";

const Login = () => {
  const navigate = useNavigate();
  const authUser = useAppContext();

  const { t } = useTranslation();

  const [showPassword1, setShowPassword1] = useState(false);

  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const [addLogin, setAddLogin] = useState({
    email: "",
    password: "",
  });

  const handleShowPassword1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddLogin((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddLogin = async (e) => {
    e.preventDefault();

    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);

    authUser.handleSetAuth(addLogin);

    Object.entries(addLogin).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    const response = await fetch(`${LinkLikeBachend}read/login.php`, {
      method: "POST",
      body: FormDataSubmit,
    });

    const resultText = await response.text();
    const [resultSuccess, token] = resultText.split("|");

    if (response.ok) {
      if (resultSuccess == "successfully") {
        toast.success(t("signin_success"));

        setAddLogin({
          email: "",
          password: "",
        });

        setDisabledSubmit(false);

        Cookies.set("successfully", "true", { expires: 1 });
        Cookies.set("token", token, { expires: 1 });

        const redirectFrom = sessionStorage.getItem("redirectFrom");

        if (redirectFrom) {
          navigate(redirectFrom, { replace: true });
        } else {
          navigate("/home", { replace: true });
        }
      } else if (resultText == "account_not_activated") {
        toast.error(t("signin_account_not_activated"));

        setDisabledSubmit(false);

        navigate("/account-activated");
      } else if (resultText == "password_incorrect") {
        toast.error(t("signin_password_incorrect"));

        setDisabledSubmit(false);
      } else if (resultText == "email_incorrect") {
        toast.error(t("signin_email_incorrect"));

        setDisabledSubmit(false);
      } else {
        toast.error(t("signin_error"));

        console.log(resultText);
        setDisabledSubmit(false);
      }
    } else {
      const errorText = await response.text();
      toast.error(t("signin_error"));
      console.log("حدث خطأ:", errorText);
      setDisabledSubmit(false);
    }
  };

  // console.log(addLogin);

  return (
    <form className="form" onSubmit={handleAddLogin}>
      <p className="title">{t("signin")}</p>
      <label>
        <input
          required
          placeholder=""
          name="email"
          type="email"
          className="input"
          value={addLogin.email}
          onChange={handleChange}
        />
        <span>{t("email")}</span>
      </label>

      <label>
        <input
          required
          placeholder=""
          name="password"
          type={showPassword1 ? "text" : "password"}
          className="input"
          value={addLogin.password}
          onChange={handleChange}
        />
        <span>{t("password")}</span>

        {showPassword1 ? (
          <FaEyeSlash className="faeye_show" onClick={handleShowPassword1} />
        ) : (
          <FaEye className="faeye_show" onClick={handleShowPassword1} />
        )}
      </label>

      <p className="signin">
        <NavLink to="/forgot-password">{t("forgot_password")}</NavLink>
      </p>

      <button
        className="submit"
        disabled={isDisabledSubmit}
        style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
      >
        {t("submit")}
      </button>
      <p className="signin">
        {t("already_account")} <NavLink to="/register">{t("register")}</NavLink>
      </p>
    </form>
  );
};

export default Login;
