import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./adminViewPurchase.css";

const AdminViewPurchase = () => {
  const { ticketsId } = useParams();

  const [allPurchaseHistory, setPurchaseHistory] = useState([]);
  const [infoDiscount, setInfoDiscount] = useState([]);
  const [idOffer, setIdOffer] = useState("");

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const handlePurchaseHistory = async () => {
    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("ticketsId", ticketsId);

    const response = await fetch(
      `${LinkLikeBachend}read/purchase_history_admin.php`,
      {
        method: "POST",
        body: FormDataSubmit,
      }
    );

    if (response.ok) {
      try {
        const resultData = await response.json();
        setPurchaseHistory(resultData);
        setIdOffer(resultData[0].id_offer);
      } catch (error) {
        console.error("Error :", error);
      }
    }
  };

  let loadPage = useRef(true);

  const handleDiscount = async () => {
    const formDiscount = new FormData();
    formDiscount.append("validation", validation);
    formDiscount.append("discountID", idOffer);

    try {
      const responseUser = await fetch(
        `${LinkLikeBachend}read/discount_view_admin.php`,
        {
          method: "POST",
          body: formDiscount,
        }
      );
      const discount = await responseUser.json();
      setInfoDiscount(discount);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (loadPage.current) {
      loadPage.current = false;
      handlePurchaseHistory();
      handleDiscount();
    }
  }, [idOffer]);

  // console.log(allPurchaseHistory);
  // console.log(idOffer);
  // console.log(infoDiscount);

  return (
    <>
      {allPurchaseHistory?.map((item, i) => {
        const payment_information = JSON.parse(item.payment_information);
        // const referral_tickets_tour = JSON.parse(item.referral_tickets_tour);
        // const dataArray = Object.keys(referral_tickets_tour).map(
        //   (key) => referral_tickets_tour[key]
        // );

        return (
          <div className="admin_view_purchase" key={i}>
            <div className="img_picture">
              <img src={`${LinkLikeBachend}${item.picture}`} alt="picture" />
            </div>
            {item.payment_status == 1 ? (
              <b>
                حالة السداد{" "}
                <small style={{ color: "green" }}>عملية دفع ناجحة</small>
              </b>
            ) : (
              <b>
                حالة السداد{" "}
                <small style={{ color: "red" }}>عملية دفع فاشلة</small>
              </b>
            )}
            <b>
              تاريخ الدفع: <small>{item.date_payment}</small>
            </b>
            <b>
              عدد التذاكر: <small>{item.number_tickets}</small>
            </b>
            <b>
              رابط الجولة:{" "}
              <small>
                <a href={item.link_watch_tour} target="_blank">
                  رابط مشاهدة الجولة
                </a>
              </small>
            </b>
            <div className="payment_information">
              <b>
                معرف الدفع: <small>{item.id_payment}</small>
              </b>
              <b>
                المبلغ المدفوع:{" "}
                <small>{payment_information.amount_cents / 100} SAR</small>
              </b>

              {infoDiscount && infoDiscount.length > 0 ? (
                <>
                  <b>
                    خصم: <small>{infoDiscount[0].discount}%</small>
                  </b>

                  <b>
                    كود الخصم: <small>{infoDiscount[0].code_discount}</small>
                  </b>
                </>
              ) : (
                <b>
                  خصم: <small>لا يوجد خصم</small>
                </b>
              )}
            </div>

            {/* <div className="payment_information">
              {dataArray.map((ref, i) => (
                <div className="payment_information" key={i}>
                  <b>
                    email: <small>{ref.email}</small>
                  </b>

                  {ref.isWatched == 0 ? (
                    <>
                      <b>
                        Viewing status:{" "}
                        <small style={{ color: "orange" }}>waiting</small>
                      </b>
                    </>
                  ) : (
                    <>
                      <b>
                        Viewing status{" "}
                        <small style={{ color: "green" }}>Watched</small>
                      </b>
                    </>
                  )}
                </div>
              ))}
            </div> */}
          </div>
        );
      })}
    </>
  );
};

export default AdminViewPurchase;
