import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import "./account.css";

const InfoAccount = () => {
  const { t } = useTranslation();
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const [accountName, setAccountName] = useState("");

  const fetchDataAccount = async () => {
    const formDataAccount = new FormData();
    formDataAccount.append("validation", validation);
    formDataAccount.append("token", token);

    try {
      const responseSliderProducts = await fetch(
        `${LinkLikeBachend}read/view_user_account.php`,
        {
          method: "POST",
          body: formDataAccount,
        }
      );
      const dataSliderProducts = await responseSliderProducts.json();

      setAccountName(dataSliderProducts[0]?.name_user);
    } catch (error) {
      Cookies.remove("successfully");
      Cookies.remove("token");

      navigate("/login", { replace: true });

      // console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataAccount();
  }, []);

  // console.log(accountName);

  return (
    <div className="InfoAccount">
      <div className="form">
        <p className="title">
          {t("welcome_user")} {accountName}
        </p>

        <NavLink to="/account" className="ticket_referral">
          {t("account_information")}
        </NavLink>

        <NavLink to="/purchase-history" className="ticket_referral">
          {t("purchase_history")}
        </NavLink>

        <NavLink to="/ticket-referral" className="ticket_referral">
          {t("invitations_virtual_tours")}
        </NavLink>
      </div>
    </div>
  );
};

export default InfoAccount;
