export const arabicDataAr = {
  language: "اللغة",
  home: "الرئيسية",
  about_us: "نبذة عن المدينة",
  privacy_policy: "الشروط والاحكام",
  contact_us: "التواصل معنا",
  purchase_history: "سجل المشتريات",
  name_site: "مدينة الطيبات العالمية",
  store: "المتجر الالكتروني",
  tayebatCityEvents: "فعاليات مدينة الطيبات",
  howUse: "طريقة الاستخدام",
  ///////////////////
  register: "تسجيل حساب",
  signin: "تسجيل دخول",
  forgot_password: "نسيت كلمة المرور",
  activate_account: "تفعيل الحساب",
  account_information: "معلومات الحساب",
  account_nav: "حسابي",

  first_name: "الاسم الأول",
  last_name: "اسم العائلة",
  age: "العمر",
  email: "بريد إلكتروني",
  password: "كلمة المرور",
  confirm_password: "تأكيد كلمة المرور",
  submit: "ارسال",
  already_account: "هل لديك حساب؟",

  register_success: "تم تسجيل الحساب بنجاح",
  logged_out_success: "تم تسجيل الخروج بنجاح",
  register_error: "خطأ في تسجيل الحساب",
  register_Phone_already: "تم استخدام رقم الهاتف من قبل",
  register_email_already: "تم استخدام البريد من قبل",

  signin_success: "تم تسجيل الدخول بنجاح",
  signin_error: "خطأ في تسجيل الدخول",
  signin_account_not_activated: "الحساب غير مفعل",
  signin_password_incorrect: "كلمة المرور خاطئة",
  signin_email_incorrect: "البريد غير مسجل",
  phone_empty: "رقم الهاتف فارغ",
  activation_code_empty: "حقل فارغ",

  AccountActivated_phone_incorrect: "رقم الهاتف غير مسجل",
  AccountActivated_success:
    "تم ارسال رقم تفعيل الحساب على الواتساب والبريد الالكتروني",
  AccountActivated_error:
    "خطأ في ارسال رقم تفعيل الحساب على الواتساب والبريد الالكتروني",

  account_been_confirmed_successfully: "تم تأكيد الحساب بنجاح",
  confirmation_number_incorrect: "رقم تأكيد الحساب خاطئ",

  AccountActivated_success_forgotPassword:
    "تم ارسال رقم لتغيير كلمة المرور على الواتساب والبريد الالكتروني",
  AccountActivated_error_forgotPassword:
    "خطأ في ارسال رقم لتغيير كلمة المرور على الواتساب والبريد الالكتروني",

  new_password: "كلمة السر الجديدة",
  retype_new_password: "اعادة كتابة كلمة السر الجديدة",
  password_been_changed: "تم تغيير كلمة المرور",
  password_error_changed: "خطأ عند تغيير كلمة المرور",
  ////////////////
  password_match: "كلمة المرور غير متطابقة",
  ////////////////
  copy_right: "2023 جميع الحقوق محفوظة​ بواسطة",
  ////////////////////
  name_user: "الاسم",
  phone: "رقم الهاتف",
  country: "الدولة",
  date_registration: "تاريخ التسجيل",
  change_password: "تغيير كلمة المرور",
  seve: "حفظ",
  activation_code: "كود التفعيل",
  /////////////////
  title_message: "عنوان الرسالة",
  message: "الرسالة",
  message_success: "تم ارسال الرسالة بنجاح",
  message_error: "خطأ عند ارسال الرسالة",
  keep_in_touch: "ابقى على تواصل",
  /////////////////
  no_data: "لا يوجد بيانات لعرضها",
  load_more: "تحميل المزيد",
  loading: "جاري التحميل...",
  view_details: "التفاصيل",
  saudi_riyal: "ريال سعودي",
  time_ticket: "وقت الجولة",
  hour: "ساعة",
  buy_now: "بدء الجولة الافتراضية",
  pay: "دفع",
  coun_ticket: "عدد التذاكر",
  total: "الاجمالي",
  ticket_price: "سعر التذكرة",
  taxation: "ضريبة",
  discount: "لديك كود خصم؟",
  code_discount: "كود التخفيض",
  discount_application: "تطبيق الخصم",
  no_discount_code_found: "لم يتم العثور على كود الخصم",
  has_been_discount: "تم خصم",
  discount_code_used: "تم استخدام كود الخصم",
  purchase_status_error: "خطأ في بيانات الدفع",
  checkout_process_start: "اكمل عملية الدفع لبدء الجولة الافتراضية",
  //////////////////////
  successful: "عملية دفع ناجحة",
  failed: "عملية دفع فاشلة",
  approved: "تمت عملية الدفع بنجاح",
  unspecified_failure:
    "فشل عملية الدفع، يفضل الاتصال بمزود الخدمة أو مزود البطاقة للحصول على مزيد من التوضيح.",
  insufficient_funds: "رصيد الحساب الخاص بك غير كافٍ لإتمام عملية الدفع.",
  transaction_attempt_failed_not_enrolled:
    "فشل تنفيذ معاملة ثلاثية الأبعاد (3-D Secure)",
  declined: "تم رفض عملية الدفع",
  ///////////////////////
  virtual_tour_ticket: "تذكرة جولة افتراضية",
  ////////////////////////
  payment_status: "حالة السداد",
  date_payment: "تاريخ الدفع",
  number_tickets: "عدد التذاكر",
  link_watch_tour: "بدء الجولة",
  watch: "ابدأ جولة افتراضية",
  payment_id: "معرف الدفع",
  amount_format: "المبلغ المدفوع",
  payment_type: "نوع الدفع",
  name_card: "بطاقة دفع بأسم",
  visa_number: "رقم الفيزا",
  invitation_mail: "البريد المدعو",
  viewing_status: "حالة الجولة",
  watch_time: "متبقي على انتهاء الجولة",
  waiting: "في الانتظار",
  watches: "بدء الجولة",
  watched: "اكتملت الجولة",
  use_ticket: "استخدام التذكرة",
  ////////////////////////////
  invitations_virtual_tours: "دعوات الاصدقاء",
  welcome_user: "مرحباً بك",
  subLinksPage: "أخرى",
  tayebat_international: "متاحف مدينة الطيبات العالمية",
};

export default arabicDataAr;
