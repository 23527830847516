import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./discount.css";
import { IoCloseSharp } from "react-icons/io5";

const Discount = () => {
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const [discount, setDiscount] = useState("");
  const [viewDiscount, setViewDiscount] = useState([]);
  const [viewDiscountNot, setViewDiscountNot] = useState([]);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const handleChange = (e) => {
    setDiscount(e.target.value);
  };

  const fetchDiscount = async () => {
    const formDiscount = new FormData();
    formDiscount.append("validation", validation);

    try {
      const responseUser = await fetch(
        `${LinkLikeBachend}read/discount_active.php`,
        {
          method: "POST",
          body: formDiscount,
        }
      );
      const Discount = await responseUser.json();
      setViewDiscount(Discount);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDiscountNot = async () => {
    const formDiscountNot = new FormData();
    formDiscountNot.append("validation", validation);

    try {
      const responseNot = await fetch(
        `${LinkLikeBachend}read/discount_not_active.php`,
        {
          method: "POST",
          body: formDiscountNot,
        }
      );
      const Discount = await responseNot.json();
      setViewDiscountNot(Discount);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDiscount = async (e) => {
    e.preventDefault();

    if (discount <= 50) {
      setDisabledSubmit(true);

      const FormDataSubmit = new FormData();
      FormDataSubmit.append("validation", validation);
      FormDataSubmit.append("discount", discount);

      const response = await fetch(`${LinkLikeBachend}create/discount.php`, {
        method: "POST",
        body: FormDataSubmit,
      });

      if (response.ok) {
        try {
          const resultData = await response.text();
          if (resultData === "successfully") {
            toast.success("تم إنشاء رمز الخصم");

            setDisabledSubmit(false);

            setDiscount("");

            fetchDiscount();
          } else {
            toast.error("حدث خطأ أثناء إنشاء رمز الخصم");
            console.log("Error:", resultData);
            setDisabledSubmit(false);
          }
        } catch (error) {
          toast.error("حدث خطأ أثناء إنشاء رمز الخصم");
          console.error("Error:", error);
          setDisabledSubmit(false);
        }
      }
    } else {
      toast.error("اقصى قيمة للخصم (50%)");
    }
  };

  useEffect(() => {
    fetchDiscount();
    fetchDiscountNot();
  }, []);

  const handleCopyToClipboard = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        toast.success(`تم نسخ ${code}`);
      })
      .catch((err) => {
        toast.success("خطأ عند النسخ");
      });
  };

  const handleDelete = async (discountId) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("discountId", discountId);

    const response = await fetch(`${LinkLikeBachend}update/discount.php`, {
      method: "POST",
      body: FormDataSubmit,
    });

    if (response.ok) {
      try {
        const resultData = await response.text();
        if (resultData === "successfully") {
          toast.success("تم الحذف بنجاح");

          setDisabledSubmit(false);

          fetchDiscount();
          fetchDiscountNot();
        } else {
          toast.error("حدث خطأ عند حذف البيانات");
          console.log("Error:", resultData);
          setDisabledSubmit(false);
        }
      } catch (error) {
        toast.error("حدث خطأ عند حذف البيانات");
        console.error("Error:", error);
        setDisabledSubmit(false);
      }
    }
  };

  // console.log(viewDiscount);
  // console.log(viewDiscountNot);

  return (
    <div className="admin_discounts">
      <form className="form" onSubmit={handleDiscount}>
        <p className="title">خصم</p>
        <label>
          <input
            required
            placeholder=""
            name="Discount"
            type="number"
            className="input"
            value={discount}
            onChange={handleChange}
          />
          <span>نسبة الخصم</span>
        </label>

        <button
          className="submit"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
        >
          {isDisabledSubmit ? "انتظر" : "اضافة"}
        </button>
      </form>

      <div className="full_discounts">
        <span>كود الخصم المفعل</span>
        <div className="discounts">
          {viewDiscount.length > 0 ? (
            viewDiscount.map((discount) => (
              <div key={discount.id} className="discount">
                <IoCloseSharp
                  className="closeSharp"
                  onClick={() => handleDelete(discount.id)}
                  disabled={isDisabledSubmit}
                  style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
                />
                <p>{discount.code_discount}</p>
                <small>خصم {discount.discount}%</small>

                <button
                  onClick={() => handleCopyToClipboard(discount.code_discount)}
                >
                  نسخ
                </button>

                <h6>
                  عدد الاستخدام <span>{discount.usage_count}</span>
                </h6>
              </div>
            ))
          ) : (
            <p>لا بيانات لعرضها</p>
          )}
        </div>
      </div>

      <div className="full_discounts">
        <span>كود الخصم الغير مفعل</span>
        <div className="discounts">
          {viewDiscountNot.length > 0 ? (
            viewDiscountNot.map((discount) => (
              <div key={discount.id} className="discount">
                <p>{discount.code_discount}</p>
                <small>خصم {discount.discount}%</small>

                <h6>
                  عدد الاستخدام <span>{discount.usage_count}</span>
                </h6>
              </div>
            ))
          ) : (
            <p>لا بيانات لعرضها</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Discount;
