import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

const AdminTayebatCityEvents = () => {
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const [dataAbout, setDataAbout] = useState([]);

  const [editorDataEN, setEditorDataEN] = useState(null);
  const [editorDataFR, setEditorDataFR] = useState(null);
  const [editorDataAR, setEditorDataAR] = useState(null);

  const [editorConfigEN] = useState({
    placeholder: "",
    direction: "ltr",
    minHeight: "500",
  });
  const [editorConfigFR] = useState({
    placeholder: "",
    direction: "ltr",
    minHeight: "500",
  });
  const [editorConfigAR] = useState({
    placeholder: "",
    direction: "rtl",
    minHeight: "500",
  });

  const handleSendcontentEN = (newContentEN) => {
    setEditorDataEN(newContentEN);
  };
  const handleSendcontentFR = (newContentFR) => {
    setEditorDataFR(newContentFR);
  };
  const handleSendcontentAR = (newContentAR) => {
    setEditorDataAR(newContentAR);
  };

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataAbout = async () => {
    const formDataAbout = new FormData();
    formDataAbout.append("validation", validation);

    try {
      const responseDataAbout = await fetch(
        `${LinkLikeBachend}read/view_tayebat_city_events.php`,
        {
          method: "POST",
          body: formDataAbout,
        }
      );
      const dataAbout = await responseDataAbout.json();
      setDataAbout(dataAbout);
      setEditorDataEN(dataAbout[0].editorDataEN);
      setEditorDataFR(dataAbout[0].editorDataFR);
      setEditorDataAR(dataAbout[0].editorDataAR);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataAbout();
  }, []);

  const handleUpdateAbout_admin = async (e) => {
    e.preventDefault();
    setDisabledSubmit(true);

    if (
      editorDataEN.length > 0 &&
      editorDataAR.length > 0 &&
      editorDataFR.length > 0
    ) {
      const FormDataSubmit = new FormData();
      FormDataSubmit.append("validation", validation);
      FormDataSubmit.append("editorDataEN", editorDataEN);
      FormDataSubmit.append("editorDataFR", editorDataFR);
      FormDataSubmit.append("editorDataAR", editorDataAR);

      const response = await fetch(
        `${LinkLikeBachend}update/tayebat_city_events.php`,
        {
          method: "POST",
          body: FormDataSubmit,
        }
      );

      const resultText = await response.text();

      if (response.ok) {
        if (resultText == "successfully") {
          toast.success("تم التحديث بنجاح");

          setDisabledSubmit(false);

          fetchDataAbout();
        } else {
          toast.error("حدث خطأ عند تحديث البيانات");

          console.log(resultText);
          setDisabledSubmit(false);
        }
      } else {
        const errorText = await response.text();
        toast.error("حدث خطأ عند تحديث البيانات");
        console.log("حدث خطأ:", errorText);
        setDisabledSubmit(false);
      }
    } else {
      toast.error("حقل فارغ");
    }
  };

  // console.log(addUpdateAbout);
  // console.log(dataAbout);

  return (
    <div className="about_admin">
      <h2>تحديث صفحة فعاليات مدينة الطيبات</h2>
      <form
        className="about_form"
        onSubmit={handleUpdateAbout_admin}
        encType="multipart/form-data"
      >
        <div className="data_form ar">
          <div className="description">
            <label htmlFor="description_about_ar">عربي</label>
            <JoditEditor
              value={editorDataAR || ""}
              onChange={handleSendcontentAR}
              id="description_about_ar"
              name="description_about_ar"
              config={editorConfigAR}
              className="right"
            />
          </div>
        </div>

        <div className="data_form ">
          <div className="description">
            <label htmlFor="description">انجليزي</label>
            <JoditEditor
              name="description_about_en"
              id="description_about_en"
              value={editorDataEN || ""}
              onChange={handleSendcontentEN}
              config={editorConfigEN}
            />
          </div>
        </div>

        <div className="data_form">
          <div className="description">
            <label htmlFor="description">فرنسي</label>
            <JoditEditor
              name="description_about_fr"
              id="description_about_fr"
              value={editorDataFR || ""}
              onChange={handleSendcontentFR}
              config={editorConfigFR}
            />
          </div>
        </div>

        <input
          type="submit"
          value={isDisabledSubmit ? "انتظار" : "تحديث"}
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
        />
      </form>
    </div>
  );
};

export default AdminTayebatCityEvents;
