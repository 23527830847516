export const englishDataFR = {
  language: "langue",
  home: "Maison",
  about_us: "À propos de la ville",
  privacy_policy: "Termes et conditions",
  contact_us: "Contactez-nous",
  purchase_history: "Historique d'achat",
  name_site: "Ville internationale d'Al Tayebat",
  store: "Boutique en ligne",
  tayebatCityEvents: "Événements à Al Tayebat City",
  howUse: "Comment utiliser",
  ///////////////////////
  register: "Créer un compte",
  signin: "Se connecter",
  forgot_password: "j'ai oublie le mot de passe",
  activate_account: "activer le compte",
  account_information: "Information sur le compte",
  account_nav: "Arithmétique",

  first_name: "Prénom",
  last_name: "Nom de famille",
  age: "l'âge",
  email: "E-mail",
  password: "Mot de passe",
  confirm_password: "Confirmez le mot de passe",
  submit: "soumettre",
  already_account: "Vous avez déjà un compte?",

  register_success: "Le compte a été enregistré avec succès",
  logged_out_success: "Vous vous êtes déconnecté avec succès",
  register_error: "Erreur lors de l'enregistrement du compte",
  register_Phone_already: "Le numéro de téléphone a déjà été utilisé",
  register_email_already: "Le courrier a déjà été utilisé",

  signin_success: "Vous avez été connecté avec succès",
  signin_error: "erreur d'identification",
  signin_account_not_activated: "Le compte n'est pas activé",
  signin_password_incorrect: "Le mot de passe est incorrect",
  signin_email_incorrect: "Email non enregistré",
  phone_empty: "Le numéro de téléphone est vide",
  activation_code_empty: "Champ vide",

  AccountActivated_phone_incorrect: "Numéro de téléphone non enregistré",
  AccountActivated_success:
    "Le numéro d'activation du compte a été envoyé sur WhatsApp et e-mail",
  AccountActivated_error:
    "Erreur lors de l'envoi du numéro d'activation du compte sur WhatsApp et e-mail",

  account_been_confirmed_successfully: "Le compte a été confirmé avec succès",
  confirmation_number_incorrect:
    "Le numéro de confirmation de compte est incorrect",

  AccountActivated_success_forgotPassword:
    "Un numéro a été envoyé pour changer le mot de passe sur WhatsApp et e-mail",
  AccountActivated_error_forgotPassword:
    "Erreur lors de l'envoi d'un numéro pour changer le mot de passe sur WhatsApp et e-mail",

  new_password: "nouveau mot de passe",
  retype_new_password: "Retapez le nouveau mot de passe",
  password_been_changed: "Le mot de passe a été changé",
  password_error_changed: "Erreur lors du changement de mot de passe",
  ////////////////
  password_match: "Non concordance des mots de passe",
  ////////////////
  copy_right: "2023 Tous droits réservés​ par",
  ////////////////////
  name_user: "le nom",
  phone: "Numéro de téléphone",
  country: "Pays",
  date_registration: "date de l'inscription",
  change_password: "changer le mot de passe",
  seve: "sauvegarder",
  activation_code: "code d'activation",
  /////////////////
  title_message: "Message du titre",
  message: "Message",
  message_success: "Le message a été envoyé avec succès",
  message_error: "Erreur lors de l'envoi du message",
  keep_in_touch: "rester en contact",
  /////////////////
  no_data: "Il n'y a aucune donnée à afficher",
  load_more: "Charger plus",
  loading: "Chargement",
  view_details: "Détails",
  saudi_riyal: "Riyal Saoudien",
  time_ticket: "Durée de la visite",
  hour: "heure",
  buy_now: "Commencez la visite virtuelle",
  pay: "pousser",
  coun_ticket: "Nombre de billets",
  total: "Total",
  ticket_price: "prix de ticket",
  taxation: "impôt",
  discount: "Avez-vous un code de réduction?",
  code_discount: "Code de réduction",
  discount_application: "Demande de réduction",
  no_discount_code_found: "Aucun code de réduction trouvé",
  has_been_discount: "a été réduit",
  discount_code_used: "Le code de réduction a été utilisé",
  purchase_status_error: "Erreur dans les informations de paiement",
  checkout_process_start:
    "Terminez le processus de paiement pour démarrer la visite virtuelle",
  //////////////////////
  successful: "Paiement réussi",
  failed: "Échec du paiement",
  approved: "Le paiement a été effectué avec succès",
  unspecified_failure:
    "Échec du paiement. Veuillez contacter votre fournisseur de services ou votre fournisseur de carte pour plus de précisions.",
  insufficient_funds:
    "Le solde de votre compte est insuffisant pour terminer le processus de paiement.",
  transaction_attempt_failed_not_enrolled:
    "La transaction 3-D Secure a échoué.",
  declined: "Le paiement a été refusé",
  ///////////////////////
  virtual_tour_ticket: "Billet de visite virtuelle",
  ////////////////////////
  payment_status: "statut de paiement",
  date_payment: "date de paiement",
  number_tickets: "Nombre de billets",
  link_watch_tour: "Commencez la visite",
  watch: "Démarrer une visite virtuelle",
  payment_id: "identifiant de paiement",
  amount_format: "Le montant payé",
  payment_type: "type de paiement",
  name_card: "Carte de paiement au nom",
  visa_number: "Numéro de visa",
  invitation_mail: "Courrier invité",
  viewing_status: "Statut de la tournée",
  watch_time: "Restant jusqu'à la fin du tour",
  waiting: "en attente",
  watches: "Commencez la visite",
  watched: "La visite est terminée",
  use_ticket: "Utilisez le billet",
  ////////////////////////////
  invitations_virtual_tours: "Invitations d'amis",
  welcome_user: "Accueillir",
  subLinksPage: "Autre",
  tayebat_international: "Musées internationaux de la ville d'Al Tayebat",
};

export default englishDataFR;
