import React from "react";
import "./socialMediaLinks.css";
import {
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaFacebookF,
  FaWhatsapp,
} from "react-icons/fa";

const SocialMediaLinks = ({ social_media }) => {
  return (
    <>
      {social_media &&
        social_media.length > 0 &&
        Object.entries(social_media[0]).map(
          ([platform, link], index) =>
            link && (
              <a
                key={index}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {platform === "instagram" && <FaInstagram />}
                {platform === "tiktok" && <FaTiktok />}
                {platform === "twitter" && <FaTwitter />}
                {platform === "facebook" && <FaFacebookF />}
                {platform === "whatsapp" && <FaWhatsapp />}
              </a>
            )
        )}
    </>
  );
};

export default SocialMediaLinks;
