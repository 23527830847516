import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./resPayment.css";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const ResPayment = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const idPayment = searchParams.get("order");
  const messagePayment = searchParams.get("success");

  const { t } = useTranslation();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const navigate = useNavigate();

  // console.log(idPayment);

  let message_status;
  let message_title;
  let message_description;
  switch (messagePayment) {
    case "true":
      message_status = 1;
      message_title = t("successful");
      message_description = t("approved");
      break;

    case "false":
      message_status = 2;
      message_title = t("failed");
      message_description = t("unspecified_failure");
      break;

    default:
      message_status = 0;
      message_title = "";
      message_description = "";
  }

  const handleMailActivated = async (idPayment) => {
    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("idPayment", idPayment);

    const response = await fetch(
      `${LinkLikeBachend}read/send_mail_tickets.php`,
      {
        method: "POST",
        body: FormDataSubmit,
      }
    );

    if (response.ok) {
      try {
        const resultData = await response.text();
        console.log(resultData);

        navigate(`/view-purchase/${resultData}`, { replace: true });
      } catch (error) {
        console.error("Error :", error);
      }
    }
  };

  const fetchUpdateTicketsStatus = async () => {
    const formDataTicketsStatus = new FormData();
    formDataTicketsStatus.append("validation", validation);
    formDataTicketsStatus.append("id_payment", idPayment);
    formDataTicketsStatus.append("message_status", message_status);

    try {
      const responseTicketsStatus = await fetch(
        `${LinkLikeBachend}update/tickets_status.php`,
        {
          method: "POST",
          body: formDataTicketsStatus,
        }
      );
      const dataTicketsStatus = await responseTicketsStatus.text();

      if (dataTicketsStatus == 1) {
        handleMailActivated(idPayment);
      }

      console.log(dataTicketsStatus);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  let loadPage = useRef(true);

  useEffect(() => {
    if (loadPage.current) {
      loadPage.current = false;
      fetchUpdateTicketsStatus();
    }
  }, []);

  return (
    <div className="payment_status">
      <div className="payment_status_div">
        <div className="message">
          <div
            className="check"
            style={{ background: message_status == 1 ? "#71c341" : "#f00" }}
          >
            &#10004;
          </div>
          <p style={{ color: message_status == 1 ? "#71c341" : "#f00" }}>
            {message_title}
          </p>
          <p>{message_description}</p>
        </div>
      </div>
    </div>
  );
};

export default ResPayment;
