import React, { useEffect, useState } from "react";
import "./virtualTours.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const VirtualTours = () => {
  const [viewTours, setViewTours] = useState([]);
  const [toursCount, setToursCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataTours = async () => {
    const formDataTours = new FormData();
    formDataTours.append("validation", validation);

    try {
      const responseTours = await fetch(`${LinkLikeBachend}read/tours.php`, {
        method: "POST",
        body: formDataTours,
      });
      const dataTours = await responseTours.json();
      setViewTours(dataTours);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDataToursCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);

      const responseToursCount = await fetch(
        `${LinkLikeBachend}read/tours_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataToursCount = await responseToursCount.text();

      setToursCount(dataToursCount);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewToursPage = new FormData();
    formDataViewToursPage.append("validation", validation);
    formDataViewToursPage.append("page", currentPage + 1);

    try {
      const responseTours = await fetch(`${LinkLikeBachend}read/Tours.php`, {
        method: "POST",
        body: formDataViewToursPage,
      });
      const dataTours = await responseTours.json();

      setTimeout(() => {
        setViewTours((prevData) => prevData.concat(dataTours));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataTours();
    fetchDataToursCount();
  }, []);

  const handleDelete = async (idTours) => {
    const FormDataTours = new FormData();
    FormDataTours.append("validation", validation);
    FormDataTours.append("idTours", idTours);

    const response = await fetch(`${LinkLikeBachend}delete/tours.php`, {
      method: "POST",
      body: FormDataTours,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("تم الحذف بنجاح");

        fetchDataTours();
        fetchDataToursCount();
      } else {
        toast.error("حدث خطأ عند حذف البيانات");

        console.log(resultText);
      }
    } else {
      const errorText = await response.text();
      toast.error("حدث خطأ عند حذف البيانات");
      console.log("حدث خطأ:", errorText);
    }
  };

  const handleUpdate = (idTours) => {
    navigate(`/dashboard/update-tours/${idTours}`);
  };

  // console.log(Tours);
  // console.log(viewTours);
  // console.log(toursCount);

  return (
    <>
      <div className="div_load_more">
        <h2>الجوالات</h2>
      </div>

      <div className="viewTours">
        {viewTours ? (
          viewTours.length > 0 &&
          viewTours.map((item, i) => (
            <div className="tours_item" key={i}>
              <img
                src={`${LinkLikeBachend}${item.picture}`}
                alt={item.ticket_price}
              />
              <p>
                العنوان: <span className="span_price">{item.title_en}</span>
              </p>
              <p>
                سعر الذكرة:{" "}
                <span className="span_price">
                  {item.ticket_price} ريال سعودي
                </span>
              </p>
              <p>
                تاريخ الاضافة:{" "}
                <span className="span_price">{item.date_add}</span>
              </p>

              <button onClick={() => handleDelete(item.id)}>حذف</button>
              <button onClick={() => handleUpdate(item.id)}>تحديث</button>
            </div>
          ))
        ) : (
          <p>لا يوجد بيانات لعرضها</p>
        )}
      </div>

      <div className="div_load_more">
        {viewTours &&
          viewTours.length > 0 &&
          parseInt(toursCount) !== viewTours.length && (
            <button
              className="button load_more"
              onClick={handleLoadMore}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              {!isDisabledLoadMore ? "المزيد" : "تحميل..."}
            </button>
          )}
      </div>
    </>
  );
};

export default VirtualTours;
