import React, { useEffect, useState } from "react";
import "./adminContact.css";
import { toast } from "react-toastify";

const AdminContact = () => {
  const [social_media, setSocial_media] = useState([]);

  const [viewContact, setViewContact] = useState([]);
  const [contactCount, setcontactCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [contact, setContact] = useState({
    name_contact: "",
    email_contact: "",
    title_contact: "",
    message_contact: "",
    whatsapp: "",
  });
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDatasocialMedia = async () => {
    const formDatasocialMedia = new FormData();
    formDatasocialMedia.append("validation", validation);

    try {
      const responseDatasocialMedia = await fetch(
        `${LinkLikeBachend}read/social_media.php`,
        {
          method: "POST",
          body: formDatasocialMedia,
        }
      );
      const datasocialMedia = await responseDatasocialMedia.json();
      setSocial_media(datasocialMedia);
      setContact({
        instagram: datasocialMedia[0].instagram,
        tiktok: datasocialMedia[0].tiktok,
        twitter: datasocialMedia[0].twitter,
        facebook: datasocialMedia[0].facebook,
        whatsapp: datasocialMedia[0].whatsapp,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDataContact = async () => {
    const formDataContact = new FormData();
    formDataContact.append("validation", validation);

    try {
      const responseContact = await fetch(
        `${LinkLikeBachend}read/contact_us.php`,
        {
          method: "POST",
          body: formDataContact,
        }
      );
      const dataContact = await responseContact.json();
      setViewContact(dataContact);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSocialMedia = (e) => {
    const { name, value } = e.target;
    setContact((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchDatacontactCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);

      const responsecontactCount = await fetch(
        `${LinkLikeBachend}read/contact_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const datacontactCount = await responsecontactCount.text();

      setcontactCount(datacontactCount);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewcontactPage = new FormData();
    formDataViewcontactPage.append("validation", validation);
    formDataViewcontactPage.append("page", currentPage + 1);

    try {
      const responsecontact = await fetch(
        `${LinkLikeBachend}read/contact_us.php`,
        {
          method: "POST",
          body: formDataViewcontactPage,
        }
      );
      const datacontact = await responsecontact.json();

      setTimeout(() => {
        setViewContact((prevData) => prevData.concat(datacontact));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateSocialMedia = async (e) => {
    e.preventDefault();
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);

    Object.entries(contact).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    const response = await fetch(`${LinkLikeBachend}update/social_media.php`, {
      method: "POST",
      body: FormDataSubmit,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("تم التحديث بنجاح");

        setDisabledSubmit(false);

        fetchDatasocialMedia();
      } else {
        toast.error("حدث خطأ عند تحديث البيانات");

        console.log(resultText);
        setDisabledSubmit(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("حدث خطأ عند تحديث البيانات");
      console.log("حدث خطأ:", errorText);
      setDisabledSubmit(false);
    }
  };

  useEffect(() => {
    fetchDatasocialMedia();
    fetchDataContact();
    fetchDatacontactCount();
  }, []);

  // console.log(social_media);
  // console.log(contact);
  // console.log(viewContact);
  // console.log(contactCount);

  return (
    <>
      <div className="social_media_admin">
        <h2>روابط التواصل الاجتماعي</h2>
        <form
          className="social_media_admin_form"
          onSubmit={handleUpdateSocialMedia}
          encType="multipart/form-data"
        >
          <div className="data_form">
            <div className="description">
              <label htmlFor="description">instagram</label>
              <input
                type="text"
                name="instagram"
                id="instagram"
                value={contact.instagram || ""}
                placeholder="instagram"
                onChange={handleSocialMedia}
              />
            </div>
            <div className="description">
              <label htmlFor="description">tiktok</label>
              <input
                type="text"
                name="tiktok"
                id="tiktok"
                value={contact.tiktok || ""}
                placeholder="tiktok"
                onChange={handleSocialMedia}
              />
            </div>
            <div className="description">
              <label htmlFor="description">twitter</label>
              <input
                type="text"
                name="twitter"
                id="twitter"
                value={contact.twitter || ""}
                placeholder="twitter"
                onChange={handleSocialMedia}
              />
            </div>
            <div className="description">
              <label htmlFor="description">facebook</label>
              <input
                type="text"
                name="facebook"
                id="facebook"
                value={contact.facebook || ""}
                placeholder="facebook"
                onChange={handleSocialMedia}
              />
            </div>
            <div className="description">
              <label htmlFor="description">whatsapp</label>
              <input
                type="text"
                name="whatsapp"
                id="whatsapp"
                value={contact.whatsapp || ""}
                placeholder="whatsapp"
                onChange={handleSocialMedia}
              />
            </div>
          </div>

          <input
            type="submit"
            value="Update"
            disabled={isDisabledSubmit}
            style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
          />
        </form>
      </div>

      <div className="viewContact">
        {viewContact ? (
          viewContact.length > 0 &&
          viewContact.map((item, i) => (
            <div className="contact_item" key={i}>
              <p>
                تاريخ الارسال: <br />
                {item.date_contact}
              </p>
              <p>
                الاسم: <br />
                {item.name_contact}
              </p>
              <p>
                البريد: <br />
                {item.email_contact}
              </p>
              <p>
                عنوان الرسالة: <br />
                {item.title_contact}
              </p>
              <p>
                محتوى الرسالة: <br />
                {item.message_contact}
              </p>
            </div>
          ))
        ) : (
          <p>لا توجد بيانات لعرضها</p>
        )}
      </div>

      <div className="div_load_more">
        {viewContact &&
          viewContact.length > 0 &&
          parseInt(contactCount) !== viewContact.length && (
            <button
              className="button load_more"
              onClick={handleLoadMore}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              {!isDisabledLoadMore ? "المزيد" : "تحميل..."}
            </button>
          )}
      </div>
    </>
  );
};

export default AdminContact;
