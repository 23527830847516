import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import "../addTours/addTours.css";

const UpdateTours = () => {
  const { idTours } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [viewTours, setViewTours] = useState([]);

  const [editorDataAR, setEditorDataAR] = useState(null);
  const [editorDataEN, setEditorDataEN] = useState(null);
  const [editorDataFR, setEditorDataFR] = useState(null);

  const [image, setImage] = useState(null);

  const [editorConfigAR] = useState({
    placeholder: "",
    direction: "rtl",
    minHeight: "500",
  });

  const [editorConfigEN] = useState({
    placeholder: "",
    direction: "ltr",
    minHeight: "500",
  });

  const [editorConfigFR] = useState({
    placeholder: "",
    direction: "ltr",
    minHeight: "500",
  });

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const handleSendcontentAR = (newContentAR) => {
    setEditorDataAR(newContentAR);
  };

  const handleSendcontentEN = (newContentEN) => {
    setEditorDataEN(newContentEN);
  };

  const handleSendcontentFR = (newContentFR) => {
    setEditorDataFR(newContentFR);
  };

  const [addUpdateTours, setAddUpdateTours] = useState({
    link_watch_tour: "",
    title_ar: "",
    title_en: "",
    title_fr: "",
    ticket_price: 0,
  });

  const fetchDataTours = async () => {
    const formDataTours = new FormData();
    formDataTours.append("validation", validation);
    formDataTours.append("idTours", idTours);

    try {
      const responseTours = await fetch(`${LinkLikeBachend}read/tours.php`, {
        method: "POST",
        body: formDataTours,
      });
      const dataTours = await responseTours.json();
      setViewTours(dataTours);

      setAddUpdateTours({
        link_watch_tour: dataTours[0].link_watch_tour,
        title_ar: dataTours[0].title_ar,
        title_en: dataTours[0].title_en,
        title_fr: dataTours[0].title_fr,
        ticket_price: dataTours[0].ticket_price,
      });

      setImage(`${LinkLikeBachend}${dataTours[0].picture}`);

      setEditorDataAR(dataTours[0].description_ar);
      setEditorDataEN(dataTours[0].description_en);
      setEditorDataFR(dataTours[0].description_fr);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataTours();
  }, []);

  const handleChangeUpdate = (e) => {
    const { name, value } = e.target;
    setAddUpdateTours((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setSelectedImage(file);
  };

  const imageUrl = selectedImage ? URL.createObjectURL(selectedImage) : image;

  const handleUpdateTours_admin = async (e) => {
    e.preventDefault();
    setDisabledSubmit(true);

    if (
      editorDataAR != "" &&
      editorDataAR != null &&
      editorDataEN != "" &&
      editorDataEN != null &&
      editorDataFR != "" &&
      editorDataFR != null
    ) {
      const FormDataSubmit = new FormData();
      FormDataSubmit.append("validation", validation);
      FormDataSubmit.append("picture", selectedImage);
      FormDataSubmit.append("description_ar", editorDataAR);
      FormDataSubmit.append("description_en", editorDataEN);
      FormDataSubmit.append("description_fr", editorDataFR);
      FormDataSubmit.append("idTours", idTours);

      Object.entries(addUpdateTours).forEach(([key, value]) => {
        FormDataSubmit.append(key, value);
      });

      const response = await fetch(`${LinkLikeBachend}update/tours.php`, {
        method: "POST",
        body: FormDataSubmit,
      });

      const resultText = await response.text();

      if (response.ok) {
        if (resultText == "successfully") {
          let picture = document.querySelector("#picture");
          picture.value = "";

          toast.success("تم تحديث البيانات بنجاح");

          setDisabledSubmit(false);

          fetchDataTours();
        } else if (resultText == "no_changes") {
          toast.success("لا يوجد تغيير في البيانات");

          setDisabledSubmit(false);
        } else {
          toast.error("حدث خطأ عند تحديث البيانات");

          console.log(resultText);
          setDisabledSubmit(false);
        }
      } else {
        const errorText = await response.text();
        toast.error("حدث خطأ عند تحديث البيانات");
        console.log("حدث خطأ:", errorText);
        console.log(errorText);
        setDisabledSubmit(false);
      }
    } else {
      toast.error("الوصف فارغ");
    }
  };

  //   console.log(addUpdateTours);
  //   console.log(selectedImage);
  //   console.log(editorDataAR);
  //   console.log(editorDataEN);
  //   console.log(editorDataFR);
  //   console.log(viewTours);

  return (
    <div className="add_tours_admin">
      <h2>تحديث جولة</h2>
      <form
        className="add_tours_form"
        onSubmit={handleUpdateTours_admin}
        encType="multipart/form-data"
      >
        <div className="div_picture">
          <input
            type="file"
            name="picture"
            id="picture"
            accept="image/*"
            onChange={handleFileChange}
          />
          {imageUrl && (
            <div>
              <img src={imageUrl} alt="background_img" />
            </div>
          )}
        </div>

        <div className="data_form">
          <input
            type="url"
            name="link_watch_tour"
            id="link_watch_tour"
            placeholder="رابط المشاهدة"
            required
            value={addUpdateTours.link_watch_tour || ""}
            onChange={handleChangeUpdate}
          />

          <br />

          <input
            type="text"
            name="title_ar"
            id="title_ar"
            placeholder="العنوان عربي"
            required
            value={addUpdateTours.title_ar || ""}
            onChange={handleChangeUpdate}
          />

          <input
            type="text"
            name="title_en"
            id="title_en"
            placeholder="العنوان انجليزي"
            required
            value={addUpdateTours.title_en || ""}
            onChange={handleChangeUpdate}
          />

          <input
            type="text"
            name="title_fr"
            id="title_fr"
            placeholder="العنوان فرنساوي"
            required
            value={addUpdateTours.title_fr || ""}
            onChange={handleChangeUpdate}
          />
        </div>

        <div className="data_form">
          <div className="ticket_price">
            <input
              type="number"
              name="ticket_price"
              id="ticket_price"
              placeholder="سعر الذكرة"
              required
              value={addUpdateTours.ticket_price || ""}
              onChange={handleChangeUpdate}
            />

            <p>ريال سعودي</p>
          </div>
        </div>

        <div className="data_form">
          <div className="description">
            <label htmlFor="description">الوصف عربي</label>
            <JoditEditor
              name="description_ar"
              id="description_ar"
              value={editorDataAR || ""}
              onChange={handleSendcontentAR}
              config={editorConfigAR}
              required
            />
          </div>

          <div className="description description_en">
            <label htmlFor="description">الوصف انجليزي</label>
            <JoditEditor
              value={editorDataEN || ""}
              onChange={handleSendcontentEN}
              id="description_en"
              name="description_en"
              config={editorConfigEN}
              required
            />
          </div>

          <div className="description description_fr">
            <label htmlFor="description">الوصف فرنساوي</label>
            <JoditEditor
              value={editorDataFR || ""}
              onChange={handleSendcontentFR}
              id="description_fr"
              name="description_fr"
              config={editorConfigFR}
              required
            />
          </div>
        </div>

        <input
          type="submit"
          value={isDisabledSubmit ? "انتظر" : "تحديث"}
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
        />
      </form>
    </div>
  );
};

export default UpdateTours;
