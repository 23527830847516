import React, { useEffect, useState } from "react";
import "./icon.css";
import { MdSupervisorAccount } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { IoLogOutSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Icon = ({ setChecked }) => {
  const successfullyCookieValue = Cookies.get("successfully");
  const tokenCookieValue = Cookies.get("token");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [successUser, setSuccessUser] = useState(false);

  useEffect(() => {
    if (successfullyCookieValue == "true" && tokenCookieValue) {
      setSuccessUser(true);
    } else {
      setSuccessUser(false);
    }
  }, [successfullyCookieValue, tokenCookieValue]);

  const handleLogout = () => {
    Cookies.remove("successfully");
    Cookies.remove("token");

    setSuccessUser(false);

    navigate("/home", { replace: true });

    toast.success(t("logged_out_success"));

    setChecked(false);
  };

  return (
    <div className="icon_Basket_account">
      {successUser ? (
        <label
          title="logOut"
          htmlFor="logOut"
          className="label"
          onClick={handleLogout}
        >
          <IoLogOutSharp />
        </label>
      ) : (
        <label
          title="home"
          htmlFor="home"
          className="label"
          onClick={() => setChecked(false)}
        >
          <NavLink to="login">
            <MdSupervisorAccount />
          </NavLink>
        </label>
      )}
    </div>
  );
};

export default Icon;
