import React, { useEffect, useRef, useState } from "react";
import { FaRegCalendarDays } from "react-icons/fa6";
import "./adminHome.css";

const AdminHome = () => {
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const [countTicket, setCountTicket] = useState([]);

  const handelTicket = async () => {
    const formTicket = new FormData();
    formTicket.append("validation", validation);

    try {
      const responseTicket = await fetch(
        `${LinkLikeBachend}read/dashboard.php`,
        {
          method: "POST",
          body: formTicket,
        }
      );
      const dataTicket = await responseTicket.json();

      setCountTicket(dataTicket);
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };

  let loadPage = useRef(true);

  useEffect(() => {
    if (loadPage.current) {
      loadPage.current = false;
      handelTicket();
    }
  }, []);

  // console.log(countTicket);

  return (
    <div className="dashboard">
      <div className="dashboard_deposit">
        <div className="deposit_total">
          <FaRegCalendarDays />
          <p>{countTicket?.total_day} SAR</p>

          <small>اجمالي المشتريات اليومية</small>
        </div>
        <div className="deposit_total">
          <FaRegCalendarDays />
          <p>{countTicket?.total_week} SAR</p>

          <small>اجمالي المشتريات الاسبوعية</small>
        </div>
        <div className="deposit_total">
          <FaRegCalendarDays />
          <p>{countTicket?.total_month} SAR</p>

          <small>اجمالي المشتريات الشهرية</small>
        </div>
        <div className="deposit_total">
          <FaRegCalendarDays />
          <p>{countTicket?.total_year} SAR</p>

          <small>اجمالي المشتريات السنوية</small>
        </div>
      </div>

      <div className="dashboard_deposit">
        <div className="deposit_total">
          <FaRegCalendarDays />
          <p>{countTicket?.total_user}</p>

          <small>عدد المستخدمين</small>
        </div>

        <div className="deposit_total">
          <FaRegCalendarDays />
          <p>{countTicket?.total_contact_us}</p>

          <small>اجمالي المراسلات</small>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
