import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();
  let currentLanguage = i18n.language;
  let localization;

  if (currentLanguage === "ar") {
    localization = "ar";
  } else if (currentLanguage === "en") {
    localization = "en";
  } else if (currentLanguage === "fr") {
    localization = "fr";
  }

  const [dataPrivacyPolicy, setDataPrivacyPolicy] = useState([]);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataPrivacyPolicy = async () => {
    const formDataPrivacyPolicy = new FormData();
    formDataPrivacyPolicy.append("validation", validation);

    try {
      const responseDataPrivacyPolicy = await fetch(
        `${LinkLikeBachend}read/view_privacy_policy.php`,
        {
          method: "POST",
          body: formDataPrivacyPolicy,
        }
      );
      const dataPrivacyPolicy = await responseDataPrivacyPolicy.json();
      setDataPrivacyPolicy(dataPrivacyPolicy);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataPrivacyPolicy();
  }, []);

  let contentToDisplay;

  if (dataPrivacyPolicy && dataPrivacyPolicy.length > 0) {
    contentToDisplay = (() => {
      switch (localization) {
        case "ar":
          return dataPrivacyPolicy[0].editorDataAR;
        case "en":
          return dataPrivacyPolicy[0].editorDataEN;
        case "fr":
          return dataPrivacyPolicy[0].editorDataFR;
        default:
          return "";
      }
    })();
  }

  // console.log(dataPrivacyPolicy);

  return (
    <div className="about">
      <div
        className="data_description"
        dangerouslySetInnerHTML={{ __html: contentToDisplay }}
      />
    </div>
  );
};

export default PrivacyPolicy;
