export const englishDataEn = {
  language: "Language",
  home: "Home",
  about_us: "About the city",
  privacy_policy: "Terms and Conditions",
  contact_us: "Contact us",
  purchase_history: "Purchase history",
  name_site: "Al Tayebat International City",
  store: "Online store",
  tayebatCityEvents: "Al Tayebat City events",
  howUse: "How to use",
  ///////////////
  register: "Register an account",
  signin: "Login",
  forgot_password: "Forgot your password",
  activate_account: "Activate the account",
  account_information: "Account Information",
  account_nav: "Arithmetic",

  first_name: "First name",
  last_name: "Last name",
  age: "The age",
  email: "Email",
  password: "Password",
  confirm_password: "Confirm password",
  submit: "submit",
  already_account: "Already have an account?",

  register_success: "Account has been successfully registered",
  logged_out_success: "You have successfully logged out",
  register_error: "Error registering account",
  register_Phone_already: "The phone number has been used before",
  register_email_already: "Mail has been used before",

  signin_success: "You have been logged in successfully",
  signin_error: "login error",
  signin_account_not_activated: "The account is not activated",
  signin_password_incorrect: "The password is incorrect",
  signin_email_incorrect: "Email not registered",
  phone_empty: "Phone number is empty",
  activation_code_empty: "Empty field",

  AccountActivated_phone_incorrect: "Phone number not registered",
  AccountActivated_success:
    "The account activation number has been sent on WhatsApp and email",
  AccountActivated_error:
    "Error sending account activation number on WhatsApp and email",

  account_been_confirmed_successfully:
    "The account has been confirmed successfully",
  confirmation_number_incorrect: "The account confirmation number is incorrect",

  AccountActivated_success_forgotPassword:
    "A number has been sent to change the password on WhatsApp and email",
  AccountActivated_error_forgotPassword:
    "Error sending a number to change the password on WhatsApp and email",

  new_password: "New password",
  retype_new_password: "Retype the new password",
  password_been_changed: "Password has been changed",
  password_error_changed: "Error when changing password",
  ////////////////
  password_match: "Password mismatch",
  ////////////////
  copy_right: "2023 All rights reserved​ by",
  ////////////////////
  name_user: "The name",
  phone: "phone number",
  country: "Country",
  date_registration: "date of registration",
  change_password: "change password",
  seve: "save",
  activation_code: "Activation Code",
  /////////////////
  title_message: "Title message",
  message: "Message",
  message_success: "The message was sent successfully",
  message_error: "Error when sending the message",
  keep_in_touch: "keep in touch",
  /////////////////
  no_data: "There is no data to display",
  load_more: "Load more",
  loading: "Loading...",
  view_details: "Details",
  saudi_riyal: "Saudi riyal",
  time_ticket: "Tour time",
  hour: "Hour",
  buy_now: "Start the virtual tour",
  pay: "pay",
  coun_ticket: "Number of tickets",
  total: "Total",
  ticket_price: "Ticket price",
  taxation: "tax",
  discount: "Do you have a discount code?",
  code_discount: "Discount code",
  discount_application: "Discount application",
  no_discount_code_found: "No discount code found",
  has_been_discount: "has been discount",
  discount_code_used: "Discount code has been used",
  purchase_status_error: "Error in payment information",
  checkout_process_start:
    "Complete the checkout process to start the virtual tour",
  //////////////////////
  successful: "Successful payment",
  failed: "Failed payment",
  approved: "The payment was completed successfully",
  unspecified_failure:
    "Payment failed. Please contact your service provider or card provider for further clarification.",
  insufficient_funds:
    "Your account balance is insufficient to complete the payment process.",
  transaction_attempt_failed_not_enrolled: "3-D Secure transaction failed.",
  declined: "The payment was declined",
  ///////////////////////
  virtual_tour_ticket: "Virtual tour ticket",
  ////////////////////////
  payment_status: "payment status",
  date_payment: "date payment",
  number_tickets: "Number of tickets",
  link_watch_tour: "Start the tour",
  watch: "Start a virtual tour",
  payment_id: "payment id",
  amount_format: "The amount paid",
  payment_type: "payment type",
  name_card: "Payment card in the name",
  visa_number: "visa number",
  invitation_mail: "Invited mail",
  viewing_status: "Tour status",
  watch_time: "Remaining until the end of the round",
  waiting: "I'm waiting",
  watches: "Start the tour",
  watched: "The tour is complete",
  use_ticket: "Use the ticket",
  ////////////////////////////
  invitations_virtual_tours: "Invitations from friends",
  welcome_user: "Welcome",
  subLinksPage: "Other",
  tayebat_international: "Al Tayebat International City Museums",
};

export default englishDataEn;
