import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./adminPurchases.css";

const AdminPurchases = () => {
  const [allPurchaseHistory, setPurchaseHistory] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const navigate = useNavigate();

  const [purchaseHistoryCount, setPurchaseHistoryCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePurchaseHistory = async () => {
    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);

    const response = await fetch(
      `${LinkLikeBachend}read/purchase_history_admin.php`,
      {
        method: "POST",
        body: FormDataSubmit,
      }
    );

    if (response.ok) {
      try {
        const resultData = await response.json();
        setPurchaseHistory(resultData);
      } catch (error) {
        console.error("Error :", error);
      }
    }
  };

  const fetchDataPurchaseHistoryCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);

      const responsePurchaseHistoryCount = await fetch(
        `${LinkLikeBachend}read/purchase_history_count_admin.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataPurchaseHistoryCount =
        await responsePurchaseHistoryCount.text();

      setPurchaseHistoryCount(dataPurchaseHistoryCount);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewPurchaseHistoryPage = new FormData();
    formDataViewPurchaseHistoryPage.append("validation", validation);
    formDataViewPurchaseHistoryPage.append("page", currentPage + 1);

    try {
      const responsePurchaseHistory = await fetch(
        `${LinkLikeBachend}read/purchase_history_admin.php`,
        {
          method: "POST",
          body: formDataViewPurchaseHistoryPage,
        }
      );
      const dataPurchaseHistory = await responsePurchaseHistory.json();

      setTimeout(() => {
        setPurchaseHistory((prevData) => prevData.concat(dataPurchaseHistory));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  let loadPage = useRef(true);

  useEffect(() => {
    if (loadPage.current) {
      loadPage.current = false;
      handlePurchaseHistory();
      fetchDataPurchaseHistoryCount();
    }
  }, []);

  const handleViewDetails = (ticketsId) => {
    navigate(`/dashboard/admin-view-purchase/${ticketsId}`);
  };

  const handleUserDetails = (idUser) => {
    navigate(`/dashboard/update-user/${idUser}`);
  };

  // console.log(allPurchaseHistory);
  // console.log(purchaseHistoryCount);

  return (
    <>
      <div className="purchase_history">
        {allPurchaseHistory.length > 0 ? (
          allPurchaseHistory.map((item, i) => {
            let payment_information = JSON.parse(item.payment_information);

            return (
              <div className="purchase_item" key={i}>
                <div className="img_picture">
                  <img
                    src={`${LinkLikeBachend}${item.picture}`}
                    alt="img_picture"
                  />
                </div>
                <div className="data">
                  <p>تذكرة جولة افتراضية</p>

                  {item.payment_status == 1 ? (
                    <p className="green_successful">عملية دفع ناجحة</p>
                  ) : (
                    <p className="red_failed">عملية دفع فاشلة</p>
                  )}

                  <small>{item.date_payment}</small>
                </div>
                <div className="price">
                  <span>{payment_information.amount_format}</span>

                  <button onClick={() => handleViewDetails(item.id)}>
                    التفاصيل
                  </button>

                  <button onClick={() => handleUserDetails(item.user_id)}>
                    تفاصيل المستخدم
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <p>لا توجد بيانات لعرضها</p>
        )}
      </div>

      <div className="div_load_more">
        {allPurchaseHistory &&
          allPurchaseHistory.length > 0 &&
          parseInt(purchaseHistoryCount) !== allPurchaseHistory.length && (
            <button
              className="button load_more"
              onClick={handleLoadMore}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              {!isDisabledLoadMore ? "المزيد" : "تحميل"}
            </button>
          )}
      </div>
    </>
  );
};

export default AdminPurchases;
